import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { Trash2, Search, Users, Mail, Briefcase, Building, ArrowUpDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const EmployeesPage = () => {
  const { currentCompany } = useAppContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDepartment, setFilterDepartment] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

  const fetchEmployees = useCallback(async () => {
    if (currentCompany) {
      try {
        setLoading(true);
        setError(null);
        const employeesRef = collection(db, 'companies', currentCompany.id, 'employees');
        const q = query(employeesRef);
        const querySnapshot = await getDocs(q);
        const employeesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEmployees(employeesData);
      } catch (err) {
        console.error("Error fetching employees:", err);
        setError("Failed to fetch employees. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  }, [currentCompany]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const handleRowClick = (employeeId) => {
    navigate(`/employees/${employeeId}`);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleDelete = async (employeeId, e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this employee?')) {
      try {
        await deleteDoc(doc(db, 'companies', currentCompany.id, 'employees', employeeId));
        fetchEmployees();
      } catch (err) {
        console.error("Error deleting employee:", err);
        setError("Failed to delete employee. Please try again.");
      }
    }
  };

  const columns = [
    { id: 'name', name: 'Name', icon: <Users className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'email', name: 'Email', icon: <Mail className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'position', name: 'Position', icon: <Briefcase className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'department', name: 'Department', icon: <Building className="h-5 w-5 text-gray-500" />, sortable: true },
  ];

  const filteredAndSortedEmployees = employees
    .filter(employee => 
      (employee.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
       employee.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
       employee.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filterDepartment === '' || employee.department === filterDepartment)
    )
    .sort((a, b) => {
      if (sortConfig.key === 'name') {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return sortConfig.direction === 'ascending' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      }
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-white flex items-center">
          <Users className="mr-2" size={24} />
          Employees
        </h1>
      </div>

      <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search employees..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <select
          className="border rounded-lg px-4 py-2"
          value={filterDepartment}
          onChange={(e) => setFilterDepartment(e.target.value)}
        >
          <option value="">All Departments</option>
          {[...new Set(employees.map(e => e.department))].map(dept => (
            <option key={dept} value={dept}>{dept}</option>
          ))}
        </select>
      </div>
      
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.id}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                  <div className="flex items-center">
                    {column.icon}
                    <span className="ml-2" onClick={() => handleSort(column.id)}>{column.name}</span>
                    <ArrowUpDown
                      className={`inline ml-1 h-4 w-4 ${sortConfig.key === column.id ? 'text-indigo-600' : ''}`}
                      onClick={() => handleSort(column.id)}
                    />
                  </div>
                </th>
              ))}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredAndSortedEmployees.map((employee) => (
              <tr 
                key={employee.id} 
                className="hover:bg-gray-50 cursor-pointer transition duration-150 ease-in-out"
                onClick={() => handleRowClick(employee.id)}
              >
                {columns.map((column) => (
                  <td key={column.id} className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900">
                        {column.id === 'name' 
                          ? `${employee.firstName} ${employee.lastName}`
                          : employee[column.id]
                        }
                      </div>
                    </div>
                  </td>
                ))}
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={(e) => handleDelete(employee.id, e)}
                    className="text-red-600 hover:text-red-900 transition duration-150 ease-in-out"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeesPage;