import React, { useState, useCallback } from 'react';
import { Plus, Activity, Edit, Save, Trash, Calendar, Phone, Mail, Users, Clipboard, FileText, HelpCircle, ChevronDown, ChevronRight } from 'lucide-react';
import Lightbox from '../shared/Lightbox';

const EventTrackerSection = ({ events, onUpdate, readOnly = false }) => {
  const [editingEvent, setEditingEvent] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({ date: '', type: 'Phone Call', description: '' });
  const [expandedEvents, setExpandedEvents] = useState({});

  const addEvent = useCallback(() => {
    setIsLightboxOpen(true);
  }, []);

  const handleSaveNewEvent = () => {
    const newEvents = [...(events || []), newEvent];
    onUpdate(newEvents);
    setIsLightboxOpen(false);
    setNewEvent({ date: '', type: 'Phone Call', description: '' });
  };

  const updateEvent = useCallback((index, updatedEvent) => {
    const newEvents = events.map((event, i) => i === index ? updatedEvent : event);
    onUpdate(newEvents);
    setEditingEvent(null);
  }, [events, onUpdate]);

  const deleteEvent = useCallback((index) => {
    const newEvents = [...events];
    newEvents.splice(index, 1);
    onUpdate(newEvents);
  }, [events, onUpdate]);

  const eventTypes = ['Phone Call', 'Email', 'Meeting', 'Site Visit', 'Document Review', 'Interview', 'Other'];

  const getEventIcon = (type) => {
    switch (type) {
      case 'Phone Call': return <Phone className="w-5 h-5 text-blue-500" />;
      case 'Email': return <Mail className="w-5 h-5 text-green-500" />;
      case 'Meeting': return <Users className="w-5 h-5 text-purple-500" />;
      case 'Site Visit': return <Clipboard className="w-5 h-5 text-yellow-500" />;
      case 'Document Review': return <FileText className="w-5 h-5 text-red-500" />;
      case 'Interview': return <Users className="w-5 h-5 text-indigo-500" />;
      default: return <HelpCircle className="w-5 h-5 text-gray-500" />;
    }
  };

  const toggleEventExpansion = (index) => {
    setExpandedEvents(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 overflow-hidden">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-base sm:text-lg font-medium text-gray-900 flex items-center min-w-0">
          <Activity className="w-5 h-5 sm:w-6 sm:h-6 mr-2 flex-shrink-0 text-indigo-500" />
          <span className="truncate">Event Tracker</span>
        </h3>
        {!readOnly && (
          <button
            type="button"
            onClick={addEvent}
            className="inline-flex items-center px-2 sm:px-3 py-1 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex-shrink-0 ml-2"
          >
            <Plus className="w-4 h-4 sm:w-5 sm:h-5 mr-1" />
            Add Event
          </button>
        )}
      </div>
      {events && events.length > 0 ? (
        <div className="space-y-3">
          {events.map((event, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-3 overflow-hidden hover:bg-gray-100 transition-colors duration-300">
              <div className="flex items-center">
                <div className="flex-shrink-0 mr-3">
                  {getEventIcon(event.type)}
                </div>
                <div className="min-w-0 flex-grow flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium text-gray-900">{event.date}</p>
                    <p className="text-sm font-medium text-indigo-600">{event.type}</p>
                  </div>
                  <button
                    onClick={() => toggleEventExpansion(index)}
                    className="ml-2 text-gray-400 hover:text-indigo-600 transition-colors duration-300"
                  >
                    {expandedEvents[index] ? (
                      <ChevronDown className="w-5 h-5" />
                    ) : (
                      <ChevronRight className="w-5 h-5" />
                    )}
                  </button>
                </div>
                {!readOnly && (
                  <div className="flex space-x-2 flex-shrink-0 ml-2">
                    <button
                      onClick={() => {
                        setEditingEvent(index);
                        setNewEvent(events[index]);
                      }}
                      className="text-gray-400 hover:text-indigo-600 transition-colors duration-300"
                    >
                      <Edit className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => deleteEvent(index)}
                      className="text-gray-400 hover:text-red-600 transition-colors duration-300"
                    >
                      <Trash className="w-5 h-5" />
                    </button>
                  </div>
                )}
              </div>
              {expandedEvents[index] && (
                <div className="mt-2 pl-8">
                  <p className="text-sm text-gray-600 break-words">{event.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-center text-indigo-400">
            <Calendar className="w-5 h-5 mr-2" />
            <h3 className="text-sm font-medium text-gray-900">No events yet</h3>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {readOnly
              ? "There are no events to display."
              : "Click 'Add Event' to start tracking."}
          </p>
        </div>
      )}

      {!readOnly && (
        <Lightbox
          isOpen={isLightboxOpen || editingEvent !== null}
          onClose={() => {
            setIsLightboxOpen(false);
            setEditingEvent(null);
            setNewEvent({ date: '', type: 'Phone Call', description: '' });
          }}
          title={editingEvent !== null ? "Edit Event" : "Add Event"}
        >
          <form onSubmit={(e) => { 
            e.preventDefault(); 
            if (editingEvent !== null) {
              updateEvent(editingEvent, newEvent);
            } else {
              handleSaveNewEvent();
            }
          }}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                value={newEvent.date}
                onChange={(e) => setNewEvent(prev => ({ ...prev, date: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Type <span className="text-red-500">*</span>
              </label>
              <select
                value={newEvent.type}
                onChange={(e) => setNewEvent(prev => ({ ...prev, type: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                required
              >
                <option value="">Select a type</option>
                {eventTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Description <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={newEvent.description}
                onChange={(e) => setNewEvent(prev => ({ ...prev, description: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {editingEvent !== null ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </Lightbox>
      )}
    </div>
  );
};

export default EventTrackerSection;