import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { db } from './firebase';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { User, FileText, Shield, Briefcase, Save, ArrowLeft, Check, X, Mail, Briefcase as Position, Users, Calendar, Cake, Heart, Flag, Zap, AlertTriangle, HelpCircle, Info } from 'lucide-react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const pageName = 'Employee Detail';

const InputField = ({ label, id, name, type, value, onChange, icon: Icon }) => (
  <div>
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={id}>
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id={id}
        type={type}
        name={name}
        value={value || ''}
        onChange={onChange}
      />
    </div>
  </div>
);

const SelectField = ({ label, id, name, value, onChange, options, icon: Icon }) => (
  <div>
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={id}>
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <select
        className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id={id}
        name={name}
        value={value || ''}
        onChange={onChange}
      >
        <option value="">Select...</option>
        {options.map(option => <option key={option} value={option}>{option}</option>)}
      </select>
    </div>
  </div>
);

const EmployeeDetailPage = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const { currentCompany } = useAppContext();
  const { user } = useAuth();
  const [employee, setEmployee] = useState({
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    department: '',
    hireDate: '',
    gender: '',
    ethnicity: '',
    dateOfBirth: '',
    disabilityStatus: '',
    veteranStatus: '',
    sexualOrientation: '',
    riskLevel: ''
  });
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [calculatedAge, setCalculatedAge] = useState(null);
  const [saveMessage, setSaveMessage] = useState('');

  const ethnicityOptions = [
    'African American', 'Asian', 'Caucasian', 'Hispanic', 'Native American',
    'Pacific Islander', 'Two or More Races', 'Other', 'Prefer not to say'
  ];
  const genderOptions = ['Male', 'Female', 'Non-Binary', 'Prefer not to say'];
  const disabilityStatusOptions = ['No disability', 'Has disability', 'Prefer not to say'];
  const veteranStatusOptions = ['Veteran', 'Non-veteran', 'Prefer not to say'];
  const sexualOrientationOptions = ['Heterosexual', 'Homosexual', 'Bisexual', 'Other', 'Prefer not to say'];
  const riskLevelOptions = ['Low', 'Medium', 'High'];

  useEffect(() => {
    const fetchEmployeeAndCases = async () => {
      if (!currentCompany) {
        console.log('Current company not available yet');
        return;
      }

      try {
        setLoading(true);
        const employeeDoc = await getDoc(doc(db, 'companies', currentCompany.id, 'employees', employeeId));
        if (employeeDoc.exists()) {
          const employeeData = employeeDoc.data();
          setEmployee(prevState => ({
            ...prevState,
            ...employeeData
          }));
          
          // Calculate age if dateOfBirth exists
          if (employeeData.dateOfBirth) {
            setCalculatedAge(calculateAge(employeeData.dateOfBirth));
          }
          
          // Fetch related cases
          const casesRef = collection(db, 'cases');
          const q = query(casesRef, where("employeeId", "==", employeeId));
          const querySnapshot = await getDocs(q);
          const casesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCases(casesData);
        } else {
          setError('Employee not found');
        }
      } catch (err) {
        console.error('Error fetching employee data:', err);
        setError('Failed to fetch employee data');
      } finally {
        setLoading(false);
      }
    };

    if (currentCompany && employeeId) {
      fetchEmployeeAndCases();
    }
  }, [currentCompany, employeeId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployee(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const employeeRef = doc(db, 'companies', currentCompany.id, 'employees', employeeId);
      await updateDoc(employeeRef, employee);
      setSaveMessage('Employee data updated successfully');
      setTimeout(() => setSaveMessage(''), 3000); // Clear message after 3 seconds
    } catch (err) {
      console.error('Error updating employee:', err);
      setSaveMessage('Failed to update employee data');
      setTimeout(() => setSaveMessage(''), 3000);
    }
  };

  // Function to calculate age
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  if (!currentCompany || loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  if (!employee) return <div className="p-4">Employee not found</div>;

  const protectedClasses = [
    { name: 'Age 40+', value: employee.dateOfBirth ? (new Date().getFullYear() - new Date(employee.dateOfBirth).getFullYear() >= 40) : null },
    { name: 'Gender', value: employee.gender === 'Male' ? false : (employee.gender === 'Prefer not to say' || !employee.gender ? null : true) },
    { name: 'Race/Ethnicity', value: employee.ethnicity === 'Caucasian' ? false : (employee.ethnicity === 'Prefer not to say' || !employee.ethnicity ? null : true) },
    { name: 'Disability', value: employee.disabilityStatus === 'Has disability' ? true : (employee.disabilityStatus === 'Prefer not to say' || !employee.disabilityStatus ? null : false) },
    { name: 'Veteran', value: employee.veteranStatus === 'Veteran' ? true : (employee.veteranStatus === 'Prefer not to say' || !employee.veteranStatus ? null : false) },
    { name: 'Sexual Orientation', value: employee.sexualOrientation === 'Heterosexual' ? false : (employee.sexualOrientation === 'Prefer not to say' || !employee.sexualOrientation ? null : true) },
  ];

  const getProtectedClassIcon = (value) => {
    if (value === null) {
      return <HelpCircle className="text-gray-400 rounded-full bg-gray-100 p-1.5" size={24} />;
    } else if (value === true) {
      return <AlertTriangle className="text-red-500 rounded-full bg-red-100 p-1.5" size={28} />;
    } else {
      return <X className="text-gray-300 rounded-full bg-gray-100 p-1.5" size={24} />;
    }
  };

  const getRiskIcon = (riskLevel) => {
    switch (riskLevel) {
      case 'High':
        return <AlertTriangle className="text-red-500 rounded-full bg-red-100 p-1.5" size={28} />;
      case 'Medium':
        return <AlertTriangle className="text-yellow-500 rounded-full bg-yellow-100 p-1.5" size={26} />;
      case 'Low':
        return <X className="text-gray-300 rounded-full bg-gray-100 p-1.5" size={24} />;
      default:
        return <HelpCircle className="text-gray-400 rounded-full bg-gray-100 p-1.5" size={24} />;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Link 
        to="/employees" 
        className="mb-4 text-blue-500 hover:text-blue-700 flex items-center transition duration-300"
      >
        <ArrowLeft className="mr-2" size={20} />
        Back to All Employees
      </Link>
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-white flex items-center">
          <User className="mr-2" size={24} />
          {pageName}: {employee.firstName} {employee.lastName}
        </h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FileText className="mr-2" size={20} />
            Employee Information
          </h2>
          {saveMessage && (
            <div className={`mb-4 p-2 rounded ${saveMessage.includes('successfully') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
              {saveMessage}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputField
                label="First Name"
                id="firstName"
                name="firstName"
                type="text"
                value={employee.firstName}
                onChange={handleInputChange}
                icon={User}
              />
              <InputField
                label="Last Name"
                id="lastName"
                name="lastName"
                type="text"
                value={employee.lastName}
                onChange={handleInputChange}
                icon={User}
              />
              <InputField
                label="Email"
                id="email"
                name="email"
                type="email"
                value={employee.email}
                onChange={handleInputChange}
                icon={Mail}
              />
              <InputField
                label="Position"
                id="position"
                name="position"
                type="text"
                value={employee.position}
                onChange={handleInputChange}
                icon={Position}
              />
              <InputField
                label="Department"
                id="department"
                name="department"
                type="text"
                value={employee.department}
                onChange={handleInputChange}
                icon={Users}
              />
              <div className="grid grid-cols-2 gap-2">
                <InputField
                  label="Hire Date"
                  id="hireDate"
                  name="hireDate"
                  type="date"
                  value={employee.hireDate}
                  onChange={handleInputChange}
                  icon={Calendar}
                />
                <InputField
                  label="Termination Date"
                  id="terminationDate"
                  name="terminationDate"
                  type="date"
                  value={employee.terminationDate || ''}
                  onChange={handleInputChange}
                  icon={Calendar}
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfBirth">
                  Date of Birth 
                  {calculatedAge !== null && (
                    <span className="text-xs font-normal text-gray-500 ml-2">
                      Age: {calculatedAge}
                    </span>
                  )}
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Cake className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="dateOfBirth"
                    type="date"
                    name="dateOfBirth"
                    value={employee.dateOfBirth || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <SelectField
                label="Gender"
                id="gender"
                name="gender"
                value={employee.gender}
                onChange={handleInputChange}
                options={genderOptions}
                icon={User}
              />
              <SelectField
                label="Ethnicity"
                id="ethnicity"
                name="ethnicity"
                value={employee.ethnicity}
                onChange={handleInputChange}
                options={ethnicityOptions}
                icon={Users}
              />
              <SelectField
                label="Disability Status"
                id="disabilityStatus"
                name="disabilityStatus"
                value={employee.disabilityStatus}
                onChange={handleInputChange}
                options={disabilityStatusOptions}
                icon={Heart}
              />
              <SelectField
                label="Veteran Status"
                id="veteranStatus"
                name="veteranStatus"
                value={employee.veteranStatus}
                onChange={handleInputChange}
                options={veteranStatusOptions}
                icon={Flag}
              />
              <SelectField
                label="Sexual Orientation"
                id="sexualOrientation"
                name="sexualOrientation"
                value={employee.sexualOrientation}
                onChange={handleInputChange}
                options={sexualOrientationOptions}
                icon={Heart}
              />
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="riskLevel">
                  Risk Level
                  <Info
                    className="ml-2 text-gray-400 cursor-help"
                    size={16}
                    data-tooltip-id="risk-level-tooltip"
                    data-tooltip-content="Risk level indicates the potential for litigation or other legal risks based on the employee's protected class status and employment history."
                  />
                  <Tooltip id="risk-level-tooltip" />
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <AlertTriangle className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <select
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="riskLevel"
                    name="riskLevel"
                    value={employee.riskLevel || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Select...</option>
                    {riskLevelOptions.map(option => <option key={option} value={option}>{option}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-6">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="submit">
                <Save className="mr-2" size={20} />
                Save Changes
              </button>
            </div>
          </form>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Shield className="mr-2" size={20} />
            Protected Classes
          </h2>
          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {protectedClasses.map((item, index) => (
              <li key={index} className="flex items-center justify-between py-2 border-b last:border-b-0">
                <span>{item.name}</span>
                <div className="flex items-center justify-center w-10 h-10">
                  {getProtectedClassIcon(item.value)}
                </div>
              </li>
            ))}
            <li className="flex items-center justify-between py-2 border-b last:border-b-0">
              <span>Risk Level</span>
              <div className="flex items-center justify-center w-10 h-10">
                {getRiskIcon(employee.riskLevel)}
              </div>
            </li>
          </ul>
          <h2 className="text-xl font-semibold mt-6 mb-4 flex items-center">
            <Briefcase className="mr-2" size={20} />
            Related Cases
          </h2>
          {cases.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Case ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cases.map((case_) => (
                  <tr key={case_.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{case_.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{case_.type}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{case_.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`/cases/${case_.id}`} className="text-indigo-600 hover:text-indigo-900">View</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-500 italic">No related cases found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailPage;