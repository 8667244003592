import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, writeBatch, doc, serverTimestamp, query, where, getDocs, limit, deleteDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { Upload, AlertCircle, CheckCircle, Download } from 'lucide-react';
import EditableEmployeeTable from './components/EditableEmployeeTable';

const expectedHeaders = [
  "First Name", "Last Name", "Email", "Position", "Department", "Hire Date",
  "Gender", "Ethnicity", "Date of Birth", "Disability Status", "Veteran Status",
  "Sexual Orientation", "Risk Level"
];

function parseCSV(csv) {
  const lines = csv.split('\n');
  const headers = lines[0].split(',').map(header => header.trim());

  // Check if headers match the expected order
  if (!arraysEqual(headers, expectedHeaders)) {
    throw new Error("CSV headers do not match the expected format. Please ensure your CSV file has the correct headers in the correct order.");
  }

  const result = [];

  for (let i = 1; i < lines.length; i++) {
    if (lines[i].trim() === '') continue; // Skip empty lines
    const values = lines[i].split(',');
    const obj = {};

    headers.forEach((header, index) => {
      let value = values[index] ? values[index].trim() : '';
      
      // Convert date format if the header is "Hire Date"
      if (header === "Hire Date") {
        value = convertDateFormat(value);
      }
      
      obj[header] = value;
    });

    result.push(obj);
  }

  return result;
}

// Helper function to convert date format
function convertDateFormat(dateString) {
  // Check if the date is in MM/DD/YYYY format
  const mmddyyyyRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
  const match = dateString.match(mmddyyyyRegex);
  
  if (match) {
    const [, month, day, year] = match;
    // Pad month and day with leading zeros if necessary
    const formattedMonth = month.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
    return `${year}-${formattedMonth}-${formattedDay}`;
  }
  
  // If the date is not in MM/DD/YYYY format, return it unchanged
  return dateString;
}

// Helper function to compare arrays
function arraysEqual(a, b) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const ImportEmployeesPage = () => {
  const { currentCompany } = useAppContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [importedData, setImportedData] = useState(null);
  const [editableData, setEditableData] = useState([]);
  const [showEditTable, setShowEditTable] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      setError(null); // Clear any previous errors
    } else {
      setFile(null);
      setError('Please select a valid CSV file.');
    }
  };

  const handleImport = async () => {
    if (!file) {
      setError('Please select a file to import.');
      return;
    }

    setImporting(true);
    setError(null);
    setSuccess(false);

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const csv = e.target.result;
        const parsedData = parseCSV(csv);
        setImportedData(parsedData);
        
        // Check for duplicates within the current company
        const duplicates = await checkForDuplicates(parsedData);
        
        if (duplicates.length > 0) {
          const duplicateData = parsedData.map(employee => ({
            ...employee,
            isDuplicate: duplicates.some(dup => dup.Email === employee.Email)
          }));
          setEditableData(duplicateData);
          setShowEditTable(true);
          setError(`Found ${duplicates.length} potential duplicate(s). Please review the highlighted rows and confirm.`);
        } else if (!validateImportData(parsedData)) {
          setEditableData(parsedData);
          setShowEditTable(true);
          setError("Some entries in the CSV file are invalid. Please correct them in the table below.");
        } else {
          // Show confirmation dialog
          if (window.confirm("Everything looks good. Do you want to proceed with the import?")) {
            await importEmployeesToFirestore(parsedData);
            setSuccess(true);
            setImporting(false);
            setTimeout(() => navigate('/employees'), 2000);
          } else {
            setImporting(false);
          }
        }
      } catch (error) {
        console.error("Error importing employees:", error);
        setError(error.message || "Failed to import employees. Please check your CSV file and try again.");
      } finally {
        setImporting(false);
      }
    };

    reader.onerror = (error) => {
      console.error("FileReader error:", error);
      setError("Error reading the file. Please try again.");
      setImporting(false);
    };

    reader.readAsText(file);
  };

  const checkForDuplicates = async (data) => {
    const duplicates = [];
    const employeesRef = collection(db, 'companies', currentCompany.id, 'employees');
    
    for (const employee of data) {
      const q = query(employeesRef, 
        where('email', '==', employee['Email']),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        duplicates.push(employee);
      }
    }
    
    return duplicates;
  };

  const validateImportData = (data) => {
    const validSexualOrientations = ['Heterosexual', 'Homosexual', 'Bisexual', 'Other', 'Prefer not to say'];
    const validRiskLevels = ['Low', 'Medium', 'High'];

    return data.every(employee => {
      return (
        employee['First Name'] &&
        employee['Last Name'] &&
        employee['Email'] &&
        employee['Position'] &&
        employee['Department'] &&
        employee['Hire Date'] &&
        employee['Gender'] &&
        employee['Ethnicity'] &&
        employee['Date of Birth'] &&
        employee['Disability Status'] &&
        employee['Veteran Status'] &&
        validSexualOrientations.includes(employee['Sexual Orientation']) &&
        validRiskLevels.includes(employee['Risk Level'])
      );
    });
  };

  const importEmployeesToFirestore = async (data) => {
    const batch = writeBatch(db);
    
    for (const employee of data) {
      const employeeRef = doc(collection(db, 'companies', currentCompany.id, 'employees'));
      batch.set(employeeRef, {
        firstName: employee['First Name'],
        lastName: employee['Last Name'],
        email: employee['Email'],
        position: employee['Position'],
        department: employee['Department'],
        hireDate: employee['Hire Date'],
        gender: employee['Gender'],
        ethnicity: employee['Ethnicity'],
        dateOfBirth: employee['Date of Birth'],
        disabilityStatus: employee['Disability Status'],
        veteranStatus: employee['Veteran Status'],
        sexualOrientation: employee['Sexual Orientation'],
        riskLevel: employee['Risk Level'],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    }

    await batch.commit();
  };

  const handleRetryImport = async () => {
    try {
      // Check for duplicates within the current company
      const duplicates = await checkForDuplicates(editableData);
      
      if (duplicates.length > 0) {
        const duplicateData = editableData.map(employee => ({
          ...employee,
          isDuplicate: duplicates.some(dup => dup.Email === employee.Email)
        }));
        setEditableData(duplicateData);
        setError(`Found ${duplicates.length} potential duplicate(s). Please review the highlighted rows and confirm.`);
      } else if (!validateImportData(editableData)) {
        setError("Some entries in the CSV file are invalid. Please correct them in the table below.");
      } else {
        // Show confirmation dialog
        if (window.confirm("Everything looks good. Do you want to proceed with the import?")) {
          await importEmployeesToFirestore(editableData);
          setSuccess(true);
          setShowEditTable(false);
          setError(null);
          setTimeout(() => navigate('/employees'), 2000);
        }
      }
    } catch (error) {
      console.error("Error importing employees:", error);
      setError("Failed to import employees. Please try again.");
    }
  };

  const handleCancelImport = () => {
    setFile(null);
    setImporting(false);
    setError(null);
    setSuccess(false);
    setImportedData(null);
    setEditableData([]);
    setShowEditTable(false);
  };

  if (user.role !== 'admin' && user.role !== 'superuser') {
    return (
      <div className="p-6 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
        <AlertCircle className="mr-2" />
        You don't have permission to access this page.
      </div>
    );
  }
 
  const templateCsv = `Instructions for filling out the Employee Import Template:
1. First Name: Enter the employee's first name
2. Last Name: Enter the employee's last name
3. Email: Enter the employee's email address
4. Position: Enter the employee's job title or position
5. Department: Enter the employee's department
6. Hire Date: Enter the hire date in YYYY-MM-DD format
7. Gender: Enter Male Female Non-Binary or Other
8. Ethnicity: Enter the employee's ethnicity (e.g. Caucasian African American Asian Hispanic etc.)
9. Date of Birth: Enter the date of birth in YYYY-MM-DD format
10. Disability Status: Enter "Has disability" or "No disability"
11. Veteran Status: Enter "Veteran" "Non-veteran" or "Prefer not to say"
12. Sexual Orientation: Enter one of the following: Heterosexual Homosexual Bisexual Other or Prefer not to say
13. Risk Level: Enter Low Medium or High
IMPORTANT: Delete all instructions (including this line) before uploading the CSV file.
Template data starts below this line:
-----------------------------------------
First Name,Last Name,Email,Position,Department,Hire Date,Gender,Ethnicity,Date of Birth,Disability Status,Veteran Status,Sexual Orientation,Risk Level
John,Doe,john.doe@example.com,Software Engineer,Engineering,2023-01-15,Male,Caucasian,1990-05-15,No disability,Non-veteran,Heterosexual,Low
Jane,Smith,jane.smith@example.com,HR Manager,Human Resources,2022-11-01,Female,African American,1988-09-22,No disability,Veteran,Prefer not to say,Medium
Alex,Johnson,alex.johnson@example.com,Sales Representative,Sales,2023-03-10,Non-Binary,Asian,1995-12-03,Has disability,Prefer not to say,Bisexual,High`;

  const downloadTemplate = () => {
    const blob = new Blob([templateCsv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "employee_import_template.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className={`max-w-4xl mx-auto ${showEditTable ? 'max-w-7xl' : ''}`}>
      <h2 className="text-3xl font-bold mb-6 text-gray-800 flex items-center">
        <Upload className="mr-2" size={32} />
        Import Employees
      </h2>
      
      <div className="mb-8 bg-blue-50 border border-blue-200 rounded-lg p-6 relative">
        <button
          onClick={downloadTemplate}
          className="absolute top-2 right-2 bg-green-500 hover:bg-green-600 text-white font-bold py-1.5 px-3 rounded text-sm flex items-center transition duration-300 ease-in-out"
        >
          <Download className="mr-1.5" size={16} />
          Download CSV Template
        </button>
        <h3 className="text-xl font-semibold mb-4 text-blue-800">Instructions</h3>
        <ol className="list-decimal list-inside space-y-2 text-blue-700">
          <li>Prepare a CSV file with the following required fields in this exact order:
            <ul className="list-disc list-inside ml-4 mt-2 text-blue-600 grid grid-cols-2 gap-2">
              {expectedHeaders.map((field, index) => (
                <li key={index}>{field}</li>
              ))}
            </ul>
          </li>
          <li>Download our CSV template or create your own using the fields above in the exact order shown.</li>
          <li>Fill in your employee data in a spreadsheet application.</li>
          <li>Ensure your CSV file has a header row with the exact field names listed above.</li>
          <li>Save your file as a CSV.</li>
          <li>Upload your completed CSV file using the form below.</li>
        </ol>
      </div>

      <div className="space-y-6">
        <div className="border-t border-b border-gray-200 py-6">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-blue-50 file:text-blue-700
              hover:file:bg-blue-100
            "
          />
          <p className="mt-2 text-sm text-gray-500">
            Accepted file types: CSV
          </p>
        </div>
        
        <button
          onClick={handleImport}
          disabled={importing || !file}
          className={`${
            importing || !file
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white font-bold py-3 px-6 rounded flex items-center justify-center transition duration-300 ease-in-out w-full`}
        >
          <Upload className="mr-2" size={20} />
          {importing ? 'Importing...' : 'Import Employees'}
        </button>

        {error && (
          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
            <AlertCircle className="mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded flex items-center">
            <CheckCircle className="mr-2" />
            Employees imported successfully! Redirecting to employees page...
          </div>
        )}

        {importedData && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Preview of Imported Data:</h3>
            <pre className="bg-gray-100 p-4 rounded overflow-auto max-h-60">
              {JSON.stringify(importedData, null, 2)}
            </pre>
          </div>
        )}

        {showEditTable && (
          <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">Edit Employee Data</h3>
            <EditableEmployeeTable 
              data={editableData} 
              setData={setEditableData} 
              validateData={validateImportData}
              onRetry={handleRetryImport}
              onCancel={handleCancelImport}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportEmployeesPage;