import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { useAppContext } from '../AppContext';
import { ClipboardList, Calendar } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { startOfMonth, subMonths, startOfYear, subYears, format } from 'date-fns';


const CaseloadReport = () => {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentCompany } = useAppContext();
  const [timeframe, setTimeframe] = useState('all');

  const getTimeframeDate = () => {
    const now = new Date();
    switch (timeframe) {
      case 'last30days':
        return subMonths(now, 1);
      case 'last90days':
        return subMonths(now, 3);
      case 'lastYear':
        return subYears(now, 1);
      case 'yearToDate':
        return startOfYear(now);
      case 'monthToDate':
        return startOfMonth(now);
      default:
        return null; // 'all' timeframe
    }
  };

  const fetchCases = useCallback(async () => {
    if (currentCompany) {
      try {
        setLoading(true);
        const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
        let q = casesRef;
        
        const startDate = getTimeframeDate();
        if (startDate) {
          q = query(casesRef, where('createdAt', '>=', startDate));
        }

        const querySnapshot = await getDocs(q);
        const cases = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const processedData = await processCaseloadData(cases);
        setReportData(processedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cases:", error);
        setError("Failed to fetch report data. Please try again.");
        setLoading(false);
      }
    }
  }, [currentCompany, timeframe]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const processCaseloadData = async (cases) => {
    const caseloadData = cases.reduce((acc, c) => {
      if (c.assignedTo) {
        if (!acc[c.assignedTo]) {
          acc[c.assignedTo] = { 
            userId: c.assignedTo,
            userName: 'Unknown',
            totalCases: 0,
            openCases: 0,
            closedCases: 0,
            caseTypes: {}
          };
        }
        acc[c.assignedTo].totalCases++;
        if (c.status === 'Closed') {
          acc[c.assignedTo].closedCases++;
        } else {
          acc[c.assignedTo].openCases++;
        }
        acc[c.assignedTo].caseTypes[c.type] = (acc[c.assignedTo].caseTypes[c.type] || 0) + 1;
      }
      return acc;
    }, {});

    // Fetch user names from top-level user collection
    const userPromises = Object.keys(caseloadData).map(async (userId) => {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        caseloadData[userId].userName = `${userData.firstName} ${userData.lastName}`;
      }
    });

    await Promise.all(userPromises);

    return Object.values(caseloadData);
  };

  const handleTimeframeChange = (e) => {
    setTimeframe(e.target.value);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-white flex items-center">
          <ClipboardList className="mr-2" size={24} />
          Caseload Report
        </h1>
      </div>

      <div className="bg-white rounded-lg overflow-hidden shadow mb-6">
        <div className="p-6">
          <div className="flex items-center mb-4">
            <Calendar className="mr-2 text-gray-500" size={20} />
            <select
              value={timeframe}
              onChange={handleTimeframeChange}
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="all">All Time</option>
              <option value="last30days">Last 30 Days</option>
              <option value="last90days">Last 90 Days</option>
              <option value="lastYear">Last Year</option>
              <option value="yearToDate">Year to Date</option>
              <option value="monthToDate">Month to Date</option>
            </select>
          </div>

          {reportData && reportData.length > 0 ? (
            <>
              <h2 className="text-xl font-semibold mb-4">Caseload Distribution</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={reportData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="userName" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="openCases" fill="#3B82F6" name="Open Cases" />
                  <Bar dataKey="closedCases" fill="#10B981" name="Closed Cases" />
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <div className="text-center text-gray-500 py-8">
              No data available for the selected timeframe.
            </div>
          )}
        </div>
      </div>

      {reportData && reportData.length > 0 && (
        <div className="bg-white rounded-lg overflow-hidden shadow">
          <div className="p-6">
            <div className="overflow-x-auto custom-scrollbar">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Cases</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Open Cases</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Closed Cases</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Case Types</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {reportData.map((user) => (
                    <tr key={user.userId}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.userName}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.totalCases}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.openCases}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.closedCases}</td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {Object.entries(user.caseTypes).map(([type, count]) => (
                          <div key={type}>{type}: {count}</div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #9CA3AF #F3F4F6;
          }
          .custom-scrollbar::-webkit-scrollbar {
            width: 12px;
            height: 12px;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background: #F3F4F6;
            border-radius: 20px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #9CA3AF;
            border-radius: 20px;
            border: 3px solid #F3F4F6;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #6B7280;
          }
        `}
      </style>
    </div>
  );
};

export default CaseloadReport;