import { Shield, Lock, Eye, FileText, UserPlus, UserCog, Trash, Settings } from 'lucide-react';

export const roleIcons = {
  admin: Shield,
  manager: Lock,
  user: Eye
};

export const permissionIcons = {
  viewCases: Eye,
  createCases: FileText,
  editCases: UserCog,
  deleteCases: Trash,
  addUsers: UserPlus,
  editUsers: UserCog,
  editCompanySettings: Settings
};

export const permissionLabels = {
  viewCases: 'View Cases',
  createCases: 'Create Cases',
  editCases: 'Edit Cases',
  deleteCases: 'Delete Cases',
  addUsers: 'Add Users',
  editUsers: 'Edit Users',
  editCompanySettings: 'Edit Company Settings'
};

export const rolePermissions = {
  admin: {
    viewCases: true,
    createCases: true,
    editCases: true,
    deleteCases: true,
    addUsers: true,
    editUsers: true,
    editCompanySettings: true
  },
  manager: {
    viewCases: true,
    createCases: true,
    editCases: true,
    deleteCases: false,
    addUsers: false,
    editUsers: false,
    editCompanySettings: false
  },
  user: {
    viewCases: true,
    createCases: false,
    editCases: false,
    deleteCases: false,
    addUsers: false,
    editUsers: false,
    editCompanySettings: false
  }
};