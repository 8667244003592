import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../AuthContext';
import { useAppContext } from '../AppContext';

const PermissionsContext = createContext(null);

export const PermissionsProvider = ({ children }) => {
  const { user, loading: userLoading } = useAppContext();
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPermissions = useCallback(async () => {
    console.log("Fetching permissions. User:", user?.uid, "User loading:", userLoading);
    if (userLoading) {
      console.log('User data is still loading, waiting...');
      return;
    }

    if (!user || !user.uid) {
      console.log('No user data available, setting default permissions');
      setPermissions({
        viewCases: false,
        createCases: false,
        editCases: false,
        deleteCases: false,
        viewReports: false,
        manageUsers: false,
      });
      setLoading(false);
      return;
    }

    try {
      const newPermissions = {
        viewCases: true,
        createCases: ['admin', 'manager', 'superuser'].includes(user.role),
        editCases: ['admin', 'manager', 'superuser'].includes(user.role),
        deleteCases: ['admin', 'superuser'].includes(user.role),
        viewReports: ['admin', 'manager', 'superuser'].includes(user.role),
        manageUsers: ['admin', 'superuser'].includes(user.role),
      };
      setPermissions(newPermissions);
      console.log("Permissions set:", newPermissions);
    } catch (error) {
      console.error('Error setting permissions:', error);
      setPermissions(null);
    } finally {
      setLoading(false);
    }
  }, [user, userLoading]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const checkPermission = useCallback((permission) => {
    return permissions ? permissions[permission] : false;
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={{ permissions, loading, checkPermission }}>
      {children}
    </PermissionsContext.Provider>
  );
};
export const usePermissions = () => useContext(PermissionsContext);
