import React, { useState, useEffect, useCallback, lazy, Suspense, useMemo, useReducer } from 'react';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { doc, updateDoc, getDoc, collection, query, where, getDocs, deleteDoc, writeBatch, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import { Save, AlertCircle, Users, Plus, CreditCard, Building, ExternalLink, Upload, CheckCircle, Download, Phone, Mail, MapPin, Briefcase, Calendar, Check, TrendingUp, Lock, Unlock, FileText, Trash, BarChart, Settings, Eye, UserPlus, Shield, UserCog, User, ChevronDown, ChevronUp } from 'lucide-react';
import AddUserForm from './shared/AddUserForm';
import Lightbox from './shared/Lightbox';
import EditUserForm from './shared/EditUserForm';
import { getSubscriptionDetails } from './utils/stripe';
import { Tab } from '@headlessui/react';
import SubscriptionSkeleton from './components/SubscriptionSkeleton';
import EditableUserTable from './components/EditableUserTable';
import { Divider } from './components/ui/Divider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { roleIcons, permissionIcons, permissionLabels, rolePermissions } from './utils/rolePermissions';

const LazySubscriptionDetails = lazy(() => import('./components/SubscriptionDetails'));
const UsersTable = lazy(() => import('./shared/UsersTable'));
const SubscriptionManager = lazy(() => import('./components/SubscriptionManager'));

const pageName = 'Settings';

const expectedUserHeaders = [
  "First Name", "Last Name", "Email", "Role", "Status"
];

function parseCSV(csv) {
  const lines = csv.split('\n');
  const headers = lines[0].split(',').map(header => header.trim());

  if (!arraysEqual(headers, expectedUserHeaders)) {
    throw new Error("CSV headers do not match the expected format. Please ensure your CSV file has the correct headers in the correct order.");
  }

  const result = [];

  for (let i = 1; i < lines.length; i++) {
    if (lines[i].trim() === '') continue;
    const values = lines[i].split(',');
    const obj = {};

    headers.forEach((header, index) => {
      obj[header] = values[index] ? values[index].trim() : '';
    });

    result.push(obj);
  }

  return result;
}

function arraysEqual(a, b) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const initialState = {
  companyData: {
    name: '',
    address: '',
    phone: '',
    email: '',
    size: '',
    industry: '',
    foundedYear: '',
    publiclyTraded: false
  },
  subscriptionDetails: null,
  subscriptionLoading: true,
  error: '',
  success: '',
  isAddUserModalOpen: false,
  isImportUserSectionVisible: false,
  editingUser: null,
  companyUsers: [],
  totalCases: 0,
  isSubscriptionTabActive: false,
  file: null,
  importing: false,
  importedData: null,
  editableData: [],
  showEditTable: false,
  companyAbbreviation: '',
  rolePermissions: {},
  expandedRoles: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_COMPANY_DATA':
      return { ...state, companyData: { ...state.companyData, ...action.payload } };
    case 'SET_SUBSCRIPTION_DETAILS':
      return { ...state, subscriptionDetails: action.payload };
    case 'SET_SUBSCRIPTION_LOADING':
      return { ...state, subscriptionLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_SUCCESS':
      return { ...state, success: action.payload };
    case 'SET_ADD_USER_MODAL':
      return { ...state, isAddUserModalOpen: action.payload };
    case 'SET_IMPORT_USER_SECTION':
      return { ...state, isImportUserSectionVisible: action.payload };
    case 'SET_EDITING_USER':
      return { ...state, editingUser: action.payload };
    case 'SET_COMPANY_USERS':
      return { ...state, companyUsers: action.payload };
    case 'SET_TOTAL_CASES':
      return { ...state, totalCases: action.payload };
    case 'SET_SUBSCRIPTION_TAB_ACTIVE':
      return { ...state, isSubscriptionTabActive: action.payload };
    case 'SET_FILE':
      return { ...state, file: action.payload };
    case 'SET_IMPORTING':
      return { ...state, importing: action.payload };
    case 'SET_IMPORTED_DATA':
      return { ...state, importedData: action.payload };
    case 'SET_EDITABLE_DATA':
      return { ...state, editableData: action.payload };
    case 'SET_SHOW_EDIT_TABLE':
      return { ...state, showEditTable: action.payload };
    case 'SET_COMPANY_ABBREVIATION':
      return { ...state, companyAbbreviation: action.payload };
    case 'SET_ROLE_PERMISSIONS':
      return { ...state, rolePermissions: action.payload };
    case 'SET_EXPANDED_ROLES':
      return { ...state, expandedRoles: action.payload };
    default:
      return state;
  }
}

const industryOptions = [
  'Technology', 'Healthcare', 'Finance', 'Education', 'Manufacturing',
  'Retail', 'Hospitality', 'Construction', 'Agriculture', 'Transportation',
  'Energy', 'Media', 'Telecommunications', 'Real Estate', 'Other'
];

const SettingsPage = () => {
  const { currentCompany, setCurrentCompany, user } = useAppContext();
  const { user: authUser } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  const tabs = useMemo(() => [
    { name: 'Company Settings', icon: Building },
    { name: 'Subscription', icon: CreditCard },
    { name: 'User Management', icon: Users },
    { name: 'Role Permissions', icon: Lock },
  ], []);

  const fetchCompanyUsers = useCallback(async () => {
    if (!currentCompany) return;
    try {
      const usersQuery = query(collection(db, 'users'), where('companyId', '==', currentCompany.id));
      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        jobTitle: doc.data().jobTitle || 'N/A'
      }));
      dispatch({ type: 'SET_COMPANY_USERS', payload: users });
    } catch (error) {
      console.error("Failed to fetch company users:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to fetch company users: " + error.message });
    }
  }, [currentCompany]);

  const fetchTotalCases = useCallback(async () => {
    if (!currentCompany) return;
    try {
      const casesQuery = query(collection(db, 'cases'), where('companyId', '==', currentCompany.id));
      const casesSnapshot = await getDocs(casesQuery);
      dispatch({ type: 'SET_TOTAL_CASES', payload: casesSnapshot.size });
    } catch (error) {
      console.error("Failed to fetch total cases:", error);
    }
  }, [currentCompany]);

  const fetchData = useCallback(async () => {
    if (!currentCompany || !user) return;
    try {
      const cacheKey = `companyData_${currentCompany.id}`;
      const cachedData = localStorage.getItem(cacheKey);
      const cacheTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
      
      const isValidCache = cacheTimestamp && (Date.now() - parseInt(cacheTimestamp, 10)) < 3600000; // 1 hour cache

      let companyData;

      if (cachedData && isValidCache) {
        companyData = JSON.parse(cachedData);
      } else {
        const companyDoc = await getDoc(doc(db, 'companies', currentCompany.id));
        if (companyDoc.exists()) {
          companyData = companyDoc.data();
        } else {
          console.log('Company document does not exist');
          return;
        }
      }

      // Handle abbreviation
      let abbreviation = companyData.abbreviation;
      if (!abbreviation || abbreviation.trim() === '') {
        abbreviation = getDefaultAbbreviation(companyData.name);
        
        // Update Firebase with the new abbreviation
        const companyRef = doc(db, 'companies', currentCompany.id);
        await updateDoc(companyRef, { abbreviation });
        
        // Update local companyData
        companyData.abbreviation = abbreviation;
      }

      dispatch({ type: 'SET_COMPANY_DATA', payload: companyData });
      dispatch({ type: 'SET_COMPANY_ABBREVIATION', payload: abbreviation });
      setOriginalData({ ...companyData, abbreviation }); // Set the original data

      if (cachedData && isValidCache) {
        console.log('Using cached company data');
      } else {
        console.log('Fetched fresh company data');
        // Update cache
        localStorage.setItem(cacheKey, JSON.stringify(companyData));
        localStorage.setItem(`${cacheKey}_timestamp`, Date.now().toString());
      }

      if (companyData.subscriptionDetails) {
        dispatch({ type: 'SET_SUBSCRIPTION_DETAILS', payload: companyData.subscriptionDetails });
      } else if (companyData.stripeSubscriptionId) {
        await fetchSubscriptionDetails(companyData.stripeSubscriptionId);
      } else {
        console.log('No Stripe Subscription ID found');
      }

      await fetchCompanyUsers();
      await fetchTotalCases();
    } catch (error) {
      console.error("Failed to fetch data:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to fetch data: " + error.message });
    }
  }, [currentCompany, user, fetchCompanyUsers, fetchTotalCases]);

  const fetchSubscriptionDetails = useCallback(async (stripeSubscriptionId) => {
    if (!stripeSubscriptionId || !currentCompany) return;
    try {
      dispatch({ type: 'SET_SUBSCRIPTION_LOADING', payload: true });
      const cacheKey = `subscriptionData_${currentCompany.id}`;
      const cachedData = localStorage.getItem(cacheKey);
      const cacheTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
      
      const isValidCache = cacheTimestamp && (Date.now() - parseInt(cacheTimestamp, 10)) < 3600000; // 1 hour cache

      let subscriptionDetails;

      if (cachedData && isValidCache) {
        subscriptionDetails = JSON.parse(cachedData);
        dispatch({ type: 'SET_SUBSCRIPTION_DETAILS', payload: subscriptionDetails });
        console.log('Using cached subscription details');
      } else {
        subscriptionDetails = await getSubscriptionDetails(stripeSubscriptionId, currentCompany.id);

        if (subscriptionDetails.error) {
          throw new Error(subscriptionDetails.error);
        }

        console.log('Fetched fresh subscription details');

        // Update Firebase only if there's a change
        const companyRef = doc(db, 'companies', currentCompany.id);
        await updateDoc(companyRef, { subscriptionDetails });

        dispatch({ type: 'SET_SUBSCRIPTION_DETAILS', payload: subscriptionDetails });

        // Update cache
        localStorage.setItem(cacheKey, JSON.stringify(subscriptionDetails));
        localStorage.setItem(`${cacheKey}_timestamp`, Date.now().toString());
      }

      dispatch({ type: 'SET_TOTAL_CASES', payload: subscriptionDetails.totalCases || 0 });
    } catch (error) {
      console.error("Failed to fetch subscription details:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to fetch subscription details: " + error.message });
    } finally {
      dispatch({ type: 'SET_SUBSCRIPTION_LOADING', payload: false });
    }
  }, [currentCompany]);

  const fetchCompanyData = useCallback(async () => {
    if (!authUser) return;

    try {
      const userDoc = await getDoc(doc(db, 'users', authUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.companyId) {
          const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
          if (companyDoc.exists()) {
            setCurrentCompany({ id: companyDoc.id, ...companyDoc.data() });
          } else {
            console.error("Company document does not exist");
          }
        } else {
          console.error("User does not have a companyId");
        }
      } else {
        console.error("User document does not exist");
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  }, [authUser, setCurrentCompany]);

  useEffect(() => {
    document.title = `${pageName} - HR Case Hub`;
  }, []);

  useEffect(() => {
    if (authUser && !currentCompany) {
      console.log("SettingsPage: Fetching company data");
      fetchCompanyData();
    }
  }, [authUser, currentCompany, fetchCompanyData]);

  useEffect(() => {
    if (currentCompany && user) {
      fetchData();
    }
  }, [currentCompany, user, fetchData]);

  useEffect(() => {
    if (currentCompany && currentCompany.name) {
      const abbreviation = currentCompany.abbreviation || getDefaultAbbreviation(currentCompany.name);
      dispatch({ type: 'SET_COMPANY_ABBREVIATION', payload: abbreviation });
    }
  }, [currentCompany]);

  const [hasChanges, setHasChanges] = useState(false);
  const [originalData, setOriginalData] = useState({});

  const handleCompanyInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'SET_COMPANY_DATA', payload: { [name]: value } });
    checkForChanges({ ...state.companyData, [name]: value }, state.companyAbbreviation);
  };

  const handleAbbreviationChange = (e) => {
    const { value } = e.target;
    dispatch({ type: 'SET_COMPANY_ABBREVIATION', payload: value });
    checkForChanges(state.companyData, value);
  };

  const checkForChanges = (currentData, currentAbbreviation) => {
    const hasDataChanges = Object.keys(currentData).some(key => currentData[key] !== originalData[key]);
    const hasAbbreviationChange = currentAbbreviation !== originalData.abbreviation;
    setHasChanges(hasDataChanges || hasAbbreviationChange);
  };

  const getDefaultAbbreviation = (companyName) => {
    if (!companyName) return '';
    return companyName
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 3);
  };

  useEffect(() => {
  console.log("Current company data:", state.companyData);
  console.log("Current abbreviation:", state.companyAbbreviation);
}, [state.companyData, state.companyAbbreviation]);

  const handleCompanySubmit = async (e) => {
    e.preventDefault();
    if (!hasChanges) return; // Don't submit if there are no changes

    dispatch({ type: 'SET_ERROR', payload: '' });
    dispatch({ type: 'SET_SUCCESS', payload: '' });
    try {
      const companyRef = doc(db, 'companies', currentCompany.id);
      let abbreviation = state.companyAbbreviation || getDefaultAbbreviation(state.companyData.name);
      abbreviation = abbreviation.trim().toUpperCase().slice(0, 3);
      
      const updatedData = {
        ...state.companyData,
        abbreviation
      };
      await updateDoc(companyRef, updatedData);
      setCurrentCompany({ ...currentCompany, ...updatedData });
      dispatch({ type: 'SET_COMPANY_ABBREVIATION', payload: abbreviation });
      setOriginalData({ ...updatedData }); // Update the original data after successful save
      setHasChanges(false); // Reset the changes flag
      dispatch({ type: 'SET_SUCCESS', payload: 'Company settings updated successfully!' });
      setTimeout(() => dispatch({ type: 'SET_SUCCESS', payload: '' }), 5000); // Clear success message after 5 seconds

      // Update local storage cache
      const cacheKey = `companyData_${currentCompany.id}`;
      localStorage.setItem(cacheKey, JSON.stringify(updatedData));
      localStorage.setItem(`${cacheKey}_timestamp`, Date.now().toString());
    } catch (error) {
      console.error('Error updating company settings:', error);
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update company settings' });
    }
  };

  const toggleUserStatus = async (userId, currentStatus) => {
    dispatch({ type: 'SET_ERROR', payload: '' });
    dispatch({ type: 'SET_SUCCESS', payload: '' });
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        isActive: !currentStatus
      });
      dispatch({ type: 'SET_SUCCESS', payload: 'User status updated successfully!' });
      await fetchCompanyUsers();
    } catch (error) {
      console.error('Error updating user status:', error);
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update user status: ' + error.message });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      dispatch({ type: 'SET_ERROR', payload: '' });
      dispatch({ type: 'SET_SUCCESS', payload: '' });
      try {
        await deleteDoc(doc(db, 'users', userId));
        dispatch({ type: 'SET_SUCCESS', payload: 'User deleted successfully!' });
        await fetchCompanyUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        dispatch({ type: 'SET_ERROR', payload: 'Failed to delete user: ' + error.message });
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      dispatch({ type: 'SET_FILE', payload: selectedFile });
      dispatch({ type: 'SET_ERROR', payload: null });
    } else {
      dispatch({ type: 'SET_FILE', payload: null });
      dispatch({ type: 'SET_ERROR', payload: 'Please select a valid CSV file.' });
    }
  };

  const handleImport = async () => {
    if (!state.file) {
      dispatch({ type: 'SET_ERROR', payload: 'Please select a file to import.' });
      return;
    }

    dispatch({ type: 'SET_IMPORTING', payload: true });
    dispatch({ type: 'SET_ERROR', payload: null });
    dispatch({ type: 'SET_SUCCESS', payload: false });

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const csv = e.target.result;
        const parsedData = parseCSV(csv);
        dispatch({ type: 'SET_IMPORTED_DATA', payload: parsedData });
        
        if (!validateImportData(parsedData)) {
          dispatch({ type: 'SET_EDITABLE_DATA', payload: parsedData });
          dispatch({ type: 'SET_SHOW_EDIT_TABLE', payload: true });
          dispatch({ type: 'SET_ERROR', payload: "Some entries in the CSV file are invalid. Please correct them in the table below." });
        } else {
          await importUsersToFirestore(parsedData);
          dispatch({ type: 'SET_SUCCESS', payload: true });
          dispatch({ type: 'SET_IMPORTING', payload: false });
          await fetchCompanyUsers();
        }
      } catch (error) {
        console.error("Error importing users:", error);
        dispatch({ type: 'SET_ERROR', payload: error.message || "Failed to import users. Please check your CSV file and try again." });
      } finally {
        dispatch({ type: 'SET_IMPORTING', payload: false });
      }
    };

    reader.onerror = (error) => {
      console.error("FileReader error:", error);
      dispatch({ type: 'SET_ERROR', payload: "Error reading the file. Please try again." });
      dispatch({ type: 'SET_IMPORTING', payload: false });
    };

    reader.readAsText(state.file);
  };

  const validateImportData = (data) => {
    const requiredFields = ["First Name", "Last Name", "Email", "Role", "Status"];

    return data.length > 0 && data.every(user => 
      requiredFields.every(field => user.hasOwnProperty(field) && user[field] !== '')
    );
  };

  const importUsersToFirestore = async (data) => {
    const batch = writeBatch(db);
    
    for (const user of data) {
      const userRef = doc(collection(db, 'users'));
      batch.set(userRef, {
        firstName: user['First Name'],
        lastName: user['Last Name'],
        email: user['Email'],
        role: user['Role'],
        status: user['Status'],
        companyId: currentCompany.id,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    }

    await batch.commit();
  };

  const handleRetryImport = async () => {
    if (validateImportData(state.editableData)) {
      try {
        await importUsersToFirestore(state.editableData);
        dispatch({ type: 'SET_SUCCESS', payload: true });
        dispatch({ type: 'SET_SHOW_EDIT_TABLE', payload: false });
        dispatch({ type: 'SET_ERROR', payload: null });
        await fetchCompanyUsers();
      } catch (error) {
        console.error("Error importing users:", error);
        dispatch({ type: 'SET_ERROR', payload: "Failed to import users. Please try again." });
      }
    } else {
      dispatch({ type: 'SET_ERROR', payload: "There are still invalid entries in the table. Please correct them and try again." });
    }
  };

  const handlePermissionChange = (role, permission) => {
    if (role === 'admin' || permission === 'editCompanySettings') return; // Prevent changes to admin permissions and editCompanySettings
    dispatch({ type: 'SET_ROLE_PERMISSIONS', payload: { role, permission } });
  };

  const saveRolePermissions = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const companyRef = doc(db, 'companies', currentCompany.id);
      await updateDoc(companyRef, { rolePermissions: state.rolePermissions });
      dispatch({ type: 'SET_ROLE_PERMISSIONS', payload: state.rolePermissions });
      dispatch({ type: 'SET_SUCCESS', payload: 'Role permissions updated successfully!' });
    } catch (error) {
      console.error('Error updating role permissions:', error);
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update role permissions' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const toggleRoleExpand = (role) => {
    dispatch({
      type: 'SET_EXPANDED_ROLES',
      payload: state.expandedRoles.includes(role)
        ? state.expandedRoles.filter(r => r !== role)
        : [...state.expandedRoles, role]
    });
  };

  const templateCsv = `First Name,Last Name,Email,Role,Status
John,Doe,john.doe@example.com,admin,active
Jane,Smith,jane.smith@example.com,user,inactive`;

  const downloadTemplate = () => {
    const blob = new Blob([templateCsv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "user_import_template.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center">
            <Building className="mr-2" size={24} />
            Settings
          </h1>
        </div>
      </div>

      <div className="bg-white rounded-lg overflow-hidden">
        <Tab.Group onChange={(index) => dispatch({ type: 'SET_SUBSCRIPTION_TAB_ACTIVE', payload: index === 1 })}>
          <Tab.List className="flex flex-col sm:flex-row bg-gray-100">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) =>
                  `flex-1 py-2.5 text-sm font-medium leading-5 focus:outline-none
                   ${
                     selected
                       ? 'bg-white text-blue-700 border-l-2 sm:border-l-0 sm:border-b-2 border-blue-700'
                       : 'text-gray-700 hover:bg-white/[0.75] hover:text-blue-700'
                   }
                   transition-all duration-200`
                }
              >
                <div className="flex items-center justify-center">
                  <tab.icon className="w-5 h-5 mr-2" />
                  {tab.name}
                </div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="pt-4">
            <Tab.Panel>
              <div className="bg-white px-4 py-5 sm:p-6">
                <h2 className="text-2xl font-bold flex items-center mb-6">
                  <Building className="mr-2" />
                  Company Settings
                </h2>
                <form onSubmit={handleCompanySubmit} className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyName">
                        Company Name
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="companyName"
                          name="name"
                          value={state.companyData?.name || ''}
                          onChange={handleCompanyInputChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          placeholder="Enter company name"
                        />
                        <Building className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyAddress">
                        Address
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="companyAddress"
                          name="address"
                          value={state.companyData?.address || ''}
                          onChange={handleCompanyInputChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          placeholder="Enter company address"
                        />
                        <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyPhone">
                        Phone
                      </label>
                      <div className="relative">
                        <input
                          type="tel"
                          id="companyPhone"
                          name="phone"
                          value={state.companyData?.phone || ''}
                          onChange={handleCompanyInputChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          placeholder="Enter phone number"
                        />
                        <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyEmail">
                        Email
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          id="companyEmail"
                          name="email"
                          value={state.companyData?.email || ''}
                          onChange={handleCompanyInputChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          placeholder="Enter company email"
                        />
                        <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companySize">
                        Company Size
                      </label>
                      <div className="relative">
                        <select
                          id="companySize"
                          name="size"
                          value={state.companyData?.size || ''}
                          onChange={handleCompanyInputChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                          <option value="">Select company size</option>
                          <option value="1-10">1-10 employees</option>
                          <option value="11-50">11-50 employees</option>
                          <option value="51-200">51-200 employees</option>
                          <option value="201-500">201-500 employees</option>
                          <option value="501-1000">501-1000 employees</option>
                          <option value="1001+">1001+ employees</option>
                        </select>
                        <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyIndustry">
                        Industry
                      </label>
                      <div className="relative">
                        <select
                          id="companyIndustry"
                          name="industry"
                          value={state.companyData?.industry || ''}
                          onChange={handleCompanyInputChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                          <option value="">Select industry</option>
                          {industryOptions.map((industry) => (
                            <option key={industry} value={industry}>
                              {industry}
                            </option>
                          ))}
                        </select>
                        <Briefcase className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyFoundedYear">
                        Founded Year
                      </label>
                      <div className="relative">
                        <input
                          type="number"
                          id="companyFoundedYear"
                          name="foundedYear"
                          value={state.companyData?.foundedYear || ''}
                          onChange={handleCompanyInputChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          placeholder="Enter founded year"
                        />
                        <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyPubliclyTraded">
                        Publicly Traded
                      </label>
                      <div className="relative">
                        <select
                          id="companyPubliclyTraded"
                          name="publiclyTraded"
                          value={state.companyData?.publiclyTraded ? 'yes' : 'no'}
                          onChange={(e) => handleCompanyInputChange({
                            target: {
                              name: 'publiclyTraded',
                              value: e.target.value === 'yes'
                            }
                          })}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                        <TrendingUp className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 my-6"></div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">Case Reference Abbreviation</h3>
                    <div className="relative">
                      <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="companyAbbreviation">
                        Company Abbreviation (3 characters)
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="companyAbbreviation"
                          name="abbreviation"
                          value={state.companyAbbreviation || ''}
                          onChange={handleAbbreviationChange}
                          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          placeholder={getDefaultAbbreviation(state.companyData?.name)}
                          maxLength={3}
                          minLength={3}
                          required
                        />
                        <Building className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                      </div>
                      <p className="mt-2 text-sm text-gray-600">
                        This 3-character abbreviation is used to create unique case reference numbers. For example, if your abbreviation is "ABC", case references will be formatted as ABC-YYYY-NNNN (e.g., ABC-2023-0001).
                      </p>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 my-6"></div>
                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                        hasChanges ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      disabled={!hasChanges}
                    >
                      <Save className="mr-2 h-5 w-5" />
                      Save Company Settings
                    </button>
                  </div>
                </form>
                {state.error && (
                  <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                    {state.error}
                  </div>
                )}
                {state.success && (
                  <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded transition-opacity duration-500 ease-in-out">
                    {state.success}
                  </div>
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="bg-white px-8 pt-6 pb-8 mb-4">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
                  <h2 className="text-2xl font-bold flex items-center mb-2 sm:mb-0">
                    <CreditCard className="mr-2" />
                    Subscription
                  </h2>
                  {state.subscriptionDetails && state.subscriptionDetails.stripePortalUrl && (
                    <a
                      href={state.subscriptionDetails.stripePortalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center mt-2 sm:mt-0"
                    >
                      Manage Subscription
                      <ExternalLink className="ml-2 h-4 w-4" />
                    </a>
                  )}
                </div>
                <Suspense fallback={<SubscriptionSkeleton />}>
                  {state.isSubscriptionTabActive && state.subscriptionDetails && (
                    <>
                      <LazySubscriptionDetails
                        subscriptionDetails={state.subscriptionDetails}
                        totalCases={state.totalCases}
                      />
               
                      <div className="mt-8">
                        <h3 className="text-xl font-semibold mb-4">Recent Invoices</h3>
                        {state.subscriptionDetails.recentInvoices && state.subscriptionDetails.recentInvoices.length > 0 ? (
                          <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice</th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {state.subscriptionDetails.recentInvoices.slice(0, 5).map((invoice) => (
                                  <tr key={invoice.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{new Date(invoice.date).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{invoice.amount}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{invoice.status}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <a href={invoice.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-900">
                                        View
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <p className="text-gray-500 italic">No recent invoices found.</p>
                        )}
                      </div>
                    </>
                  )}
                </Suspense>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="bg-white rounded-lg overflow-hidden">
                <div className="px-4 sm:px-6 lg:px-8 pt-6 pb-8">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
                    <h2 className="text-2xl font-bold flex items-center mb-4 sm:mb-0">
                      <Users className="mr-2" />
                      User Management
                    </h2>
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                      <button
                        onClick={() => dispatch({ type: 'SET_ADD_USER_MODAL', payload: true })}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                      >
                        <Plus className="mr-2" />
                        Add New User
                      </button>
                      <button
                        onClick={() => dispatch({ type: 'SET_IMPORT_USER_SECTION', payload: !state.isImportUserSectionVisible })}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                      >
                        <Upload className="mr-2" />
                        Import Users
                      </button>
                    </div>
                  </div>
                  <Suspense fallback={<div>Loading users table...</div>}>
                    <div className="overflow-hidden rounded-lg">
                      <UsersTable
                        companyUsers={state.companyUsers}
                        onToggleStatus={toggleUserStatus}
                        onEditUser={(user) => {
                          dispatch({ type: 'SET_EDITING_USER', payload: user });
                          console.log("Editing user:", user);
                        }}
                        onDeleteUser={handleDeleteUser}
                        currentUserRole={user?.role || 'user'}
                      />
                    </div>
                  </Suspense>
                  {state.isImportUserSectionVisible && (
                    <div className="mt-8">
                      <h3 className="text-xl font-semibold mb-4">Import Users</h3>
                      <div className="mb-8 bg-blue-50 border border-blue-200 rounded-lg p-6 relative">
                        <button
                          onClick={downloadTemplate}
                          className="absolute top-2 right-2 bg-green-700 hover:bg-green-800 text-white font-bold py-1.5 px-3 rounded text-sm flex items-center transition duration-300 ease-in-out"
                        >
                          <Download className="mr-1.5" size={16} />
                          Download CSV Template
                        </button>
                        <h3 className="text-xl font-semibold mb-4 text-blue-800">Instructions</h3>
                        <ol className="list-decimal list-inside space-y-2 text-blue-700">
                          <li>Prepare a CSV file with the following required fields in this exact order:
                            <ul className="list-disc list-inside ml-4 mt-2 text-blue-600 grid grid-cols-2 gap-2">
                              {expectedUserHeaders.map((field, index) => (
                                <li key={index}>{field}</li>
                              ))}
                            </ul>
                          </li>
                          <li>Download our CSV template or create your own using the fields above in the exact order shown.</li>
                          <li>Fill in your user data in a spreadsheet application.</li>
                          <li>Ensure your CSV file has a header row with the exact field names listed above.</li>
                          <li>Save your file as a CSV.</li>
                          <li>Upload your completed CSV file using the form below.</li>
                        </ol>
                      </div>
                      <div className="space-y-6">
                        <div className="border-t border-b border-gray-200 py-6">
                          <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className="block w-full text-sm text-gray-500
                              file:mr-4 file:py-2 file:px-4
                              file:rounded-full file:border-0
                              file:text-sm file:font-semibold
                              file:bg-blue-50 file:text-blue-700
                              hover:file:bg-blue-100
                            "
                          />
                          <p className="mt-2 text-sm text-gray-500">
                            Accepted file types: CSV
                          </p>
                        </div>
                        <button
                          onClick={handleImport}
                          disabled={state.importing || !state.file}
                          className={`${
                            state.importing || !state.file
                              ? 'bg-gray-300 cursor-not-allowed'
                              : 'bg-blue-500 hover:bg-blue-600'
                          } text-white font-bold py-3 px-6 rounded flex items-center justify-center transition duration-300 ease-in-out w-full`}
                        >
                          <Upload className="mr-2" size={20} />
                          {state.importing ? 'Importing...' : 'Import Users'}
                        </button>
                        {state.error && (
                          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
                            <AlertCircle className="mr-2" />
                            {state.error}
                          </div>
                        )}
                        {state.success && (
                          <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded flex items-center">
                            <CheckCircle className="mr-2" />
                            Users imported successfully!
                          </div>
                        )}
                        {state.importedData && (
                          <div className="mt-6">
                            <h3 className="text-lg font-semibold mb-2">Preview of Imported Data:</h3>
                            <pre className="bg-gray-100 p-4 rounded overflow-auto max-h-60">
                              {JSON.stringify(state.importedData, null, 2)}
                            </pre>
                          </div>
                        )}
                        {state.showEditTable && (
                          <div className="mt-8">
                            <h3 className="text-xl font-semibold mb-4">Edit User Data</h3>
                            <EditableUserTable 
                              data={state.editableData} 
                              setData={(data) => dispatch({ type: 'SET_EDITABLE_DATA', payload: data })} 
                              validateData={validateImportData}
                            />
                            <button
                              onClick={handleRetryImport}
                              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                              Retry Import
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="bg-white px-4 sm:px-6 lg:px-8 pt-6 pb-8">
                <h2 className="text-2xl font-bold flex items-center mb-6">
                  <Lock className="mr-2" />
                  Role Permissions
                </h2>
                <div className="hidden sm:block">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Permission</th>
                        {['admin', 'manager', 'user'].map((role) => {
                          const RoleIcon = roleIcons[role];
                          return (
                            <th key={role} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              <div className="flex items-center">
                                <RoleIcon className="mr-2" size={18} />
                                <span className="hidden sm:inline">{role.charAt(0).toUpperCase() + role.slice(1)}</span>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {Object.keys(rolePermissions.admin).map((permission) => {
                        const Icon = permissionIcons[permission];
                        return (
                          <tr key={permission}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                              <Icon className="mr-2" size={18} />
                              <span className="hidden sm:inline">{permissionLabels[permission]}</span>
                              <span className="sm:hidden">{permission}</span>
                            </td>
                            {['admin', 'manager', 'user'].map((role) => (
                              <td key={role} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <input
                                  type="checkbox"
                                  checked={rolePermissions[role][permission]}
                                  onChange={() => handlePermissionChange(role, permission)}
                                  disabled={role === 'admin' || permission === 'editCompanySettings'}
                                  className={`form-checkbox h-5 w-5 ${
                                    role === 'admin' || permission === 'editCompanySettings'
                                      ? 'text-gray-400 cursor-not-allowed'
                                      : 'text-blue-600 cursor-pointer'
                                  }`}
                                />
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="sm:hidden">
                  {['admin', 'manager', 'user'].map((role) => (
                    <div key={role} className="mb-6 bg-gray-50 rounded-lg p-4">
                      <div className="flex items-center justify-between mb-2">
                        <h3 className="text-lg font-semibold capitalize">{role}</h3>
                        <button
                          onClick={() => toggleRoleExpand(role)}
                          className="text-indigo-600"
                        >
                          {state.expandedRoles.includes(role) ? (
                            <ChevronUp size={20} />
                          ) : (
                            <ChevronDown size={20} />
                          )}
                        </button>
                      </div>
                      {state.expandedRoles.includes(role) && (
                        <div className="space-y-2">
                          {Object.entries(rolePermissions[role]).map(([permission, value]) => (
                            <div key={permission} className="flex items-center justify-between">
                              <span className="text-sm">{permissionLabels[permission]}</span>
                              <input
                                type="checkbox"
                                checked={value}
                                onChange={() => handlePermissionChange(role, permission)}
                                disabled={role === 'admin' || permission === 'editCompanySettings'}
                                className={`form-checkbox h-5 w-5 ${
                                  role === 'admin' || permission === 'editCompanySettings'
                                    ? 'text-gray-400 cursor-not-allowed'
                                    : 'text-blue-600 cursor-pointer'
                                }`}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <button
                  onClick={saveRolePermissions}
                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
                >
                  Save Role Permissions
                </button>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      {state.isAddUserModalOpen && (
        <Lightbox isOpen={state.isAddUserModalOpen} onClose={() => dispatch({ type: 'SET_ADD_USER_MODAL', payload: false })}>
          <AddUserForm
            currentCompany={currentCompany}
            onSuccess={(message) => {
              dispatch({ type: 'SET_SUCCESS', payload: message });
              dispatch({ type: 'SET_ADD_USER_MODAL', payload: false });
              fetchCompanyUsers();
            }}
            onClose={() => dispatch({ type: 'SET_ADD_USER_MODAL', payload: false })}
          />
        </Lightbox>
      )}

      {state.editingUser && (
        <Lightbox isOpen={!!state.editingUser} onClose={() => dispatch({ type: 'SET_EDITING_USER', payload: null })}>
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            
            <EditUserForm
              user={state.editingUser}
              currentCompany={currentCompany}
              onSuccess={(message) => {
                dispatch({ type: 'SET_SUCCESS', payload: message });
                dispatch({ type: 'SET_EDITING_USER', payload: null });
                fetchCompanyUsers();
              }}
              onClose={() => dispatch({ type: 'SET_EDITING_USER', payload: null })}
            />
          </div>
        </Lightbox>
      )}
    </div>
  );
};

export default React.memo(SettingsPage);