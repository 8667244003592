import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { UserPlus, Upload, Users } from 'lucide-react';
import ImportEmployeesPage from './ImportEmployeesPage';
import AddEmployeePage from './AddEmployeePage';
import { useAppContext } from './AppContext';

const EmployeeManagementPage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const pageName = 'Add/Import Employees';
  const { currentCompany } = useAppContext();

  useEffect(() => {
    document.title = `${pageName} - HR Case Hub`;
  }, []);

  const tabs = [
    { name: 'Add Employee', icon: UserPlus, component: AddEmployeePage },
    { name: 'Import Employees', icon: Upload, component: ImportEmployeesPage },
  ];

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center">
            <Users className="mr-2" size={24} />
            Add/Import Employees
          </h1>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex bg-gray-100">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) =>
                  `w-full py-2.5 text-sm font-medium leading-5 focus:outline-none
                   ${
                     selected
                       ? 'bg-white text-blue-700'
                       : 'text-gray-700 hover:bg-white/[0.75] hover:text-blue-700'
                   }`
                }
              >
                <div className="flex items-center justify-center">
                  <tab.icon className="w-5 h-5 mr-2" />
                  {tab.name}
                </div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="pt-4">
            {tabs.map((tab, idx) => (
              <Tab.Panel
                key={idx}
                className="bg-white p-3 focus:outline-none"
              >
                <tab.component />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default EmployeeManagementPage;