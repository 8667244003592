import React from 'react';

const SubscriptionSkeleton = () => (
  <div className="animate-pulse">
    <div className="bg-gray-200 h-8 w-1/4 mb-4 rounded"></div>
    <div className="bg-gray-200 h-24 mb-6 rounded"></div>
    <div className="space-y-2">
      <div className="bg-gray-200 h-4 w-3/4 rounded"></div>
      <div className="bg-gray-200 h-4 rounded"></div>
      <div className="bg-gray-200 h-4 w-5/6 rounded"></div>
    </div>
  </div>
);

export default SubscriptionSkeleton;