import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { debounce } from 'lodash';
import { Check, AlertCircle, Printer, ChevronDown, Edit, Trash, FileText, Clipboard } from 'lucide-react';
import InvolvedPartiesSection from '../shared/InvolvedPartiesSection';
import EventTrackerSection from '../shared/EventTrackerSection';
import Lightbox from '../shared/Lightbox';
import { useReactToPrint } from 'react-to-print';
import { Menu } from '@headlessui/react';
import { Editor } from '@tinymce/tinymce-react';

const CorrectiveAction = React.memo(({ caseData, caseId, companyId, onUpdate }) => {
  const [localCaseData, setLocalCaseData] = useState(caseData);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const pdfRef = useRef(null);

  const handlePrintCase = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: `corrective_action_${caseId}.pdf`,
  });

  const autoSave = useMemo(() => 
    debounce(async (data) => {
      if (!caseId) {
        console.error('No caseId available for autosave');
        return;
      }
      console.log('Attempting to update document with caseId:', caseId);
      const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
      
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v !== undefined)
      );

      try {
        console.log('Attempting to update Firebase with:', JSON.stringify(filteredData));
        await setDoc(caseRef, filteredData, { merge: true });
        console.log('AutoSave successful');
        
        // Verify the update
        const updatedDoc = await getDoc(caseRef);
        if (updatedDoc.exists()) {
          console.log('Updated document data:', updatedDoc.data());
        } else {
          console.error('Document does not exist after update');
        }
        
        setSaved(true);
        setTimeout(() => setSaved(false), 2000);
      } catch (error) {
        console.error("Error updating case document:", error);
        setError("Failed to save case data. Please try again.");
      }
    }, 1000),
    [caseId, companyId]
  );

  const handleInputChange = useCallback((name, value) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, [name]: value };
      console.log('Calling autoSave with:', updatedData);
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleInvolvedPartiesUpdate = useCallback((newInvolvedParties) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, involvedParties: newInvolvedParties };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleEventsUpdate = useCallback((newEvents) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, events: newEvents };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Corrective Action</h2>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            Options
            <ChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
          </Menu.Button>
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handlePrintCase}
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } group flex items-center px-4 py-2 text-sm w-full text-left`}
                  >
                    <Printer className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    Print Case
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>

      {saved && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md flex items-center shadow-lg">
          <Check className="mr-2" /> Saved
        </div>
      )}

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 mr-2" />
            <p>{error}</p>
          </div>
        </div>
      )}

      <EditableSection
        title="Policy Violation"
        fieldName="violation"
        value={localCaseData.violation}
        onEdit={handleInputChange}
        onClose={() => setEditingField(null)}
        isEditing={editingField === 'violation'}
        setIsEditing={() => setEditingField('violation')}
        icon={<FileText className="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />}
        placeholder="Describe the policy violation here..."
      />

      <EditableSection
        title="Corrective Action"
        fieldName="action"
        value={localCaseData.action}
        onEdit={handleInputChange}
        onClose={() => setEditingField(null)}
        isEditing={editingField === 'action'}
        setIsEditing={() => setEditingField('action')}
        icon={<Clipboard className="w-5 h-5 sm:w-6 sm:h-6 text-green-500" />}
        placeholder="Detail the corrective action to be taken..."
      />

      <InvolvedPartiesSection
        involvedParties={localCaseData.involvedParties}
        companyId={companyId}
        onUpdate={handleInvolvedPartiesUpdate}
      />

      <EventTrackerSection
        events={localCaseData.events}
        onUpdate={handleEventsUpdate}
      />

      {/* Hidden PDF view */}
      <div style={{ display: 'none' }}>
        <PDFView ref={pdfRef} caseData={localCaseData} />
      </div>
    </div>
  );
});

const EditableSection = ({ title, fieldName, value, onEdit, icon, isEditing, setIsEditing, onClose, placeholder }) => (
  <div className="bg-white shadow rounded-lg p-4 sm:p-6 mb-6">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-medium text-gray-900 flex items-center">
        {icon}
        <span className="ml-2">{title}</span>
      </h3>
      <button
        onClick={() => setIsEditing(true)}
        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Edit className="h-4 w-4 mr-1" />
        Edit
      </button>
    </div>
    <div className="bg-gray-50 rounded-lg p-4">
      {value ? (
        <div className="prose max-w-none text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <div className="text-center">
          <div className="flex items-center justify-center text-indigo-400">
            {React.cloneElement(icon, { className: "w-5 h-5 mr-2" })}
            <h3 className="text-sm font-medium text-gray-900">Add {title}</h3>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            Click 'Edit' to provide details for {title.toLowerCase()}.
          </p>
        </div>
      )}
    </div>
    {isEditing && (
      <LightboxEditor
        isOpen={isEditing}
        onClose={() => {
          setIsEditing(false);
          onClose();
        }}
        title={title}
        value={value}
        onEdit={onEdit}
        fieldName={fieldName}
        placeholder={placeholder}
      />
    )}
  </div>
);

const LightboxEditor = ({ isOpen, onClose, title, value, onEdit, fieldName, placeholder }) => {
  const [localValue, setLocalValue] = useState(value || '');

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleClose = () => {
    if (localValue !== value) {
      onEdit(fieldName, localValue || '');
    }
    onClose();
  };

  const handleEditorChange = (content) => {
    setLocalValue(content);
  };

  return (
    <Lightbox isOpen={isOpen} onClose={handleClose} title={title} maxWidth="max-w-4xl">
      <div className="relative">
        <Editor
          apiKey="s6m55belr5vylvppptz7d16wyge9vizqs1qgptzxxbmy629m"
          value={localValue}
          init={{
            height: 400,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
            placeholder: placeholder
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleClose}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save & Close
        </button>
      </div>
    </Lightbox>
  );
};

const PDFView = React.forwardRef(({ caseData }, ref) => (
  <div ref={ref} className="space-y-6 p-8">
    <h1 className="text-3xl font-bold">Corrective Action</h1>
    
    <section className="mb-6">
      <h2 className="text-xl font-semibold mb-2">Case Information</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4 whitespace-nowrap font-medium">Employee:</td>
            <td className="px-6 py-4 whitespace-nowrap">{caseData.employee}</td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap font-medium">Case Type:</td>
            <td className="px-6 py-4 whitespace-nowrap">Corrective Action</td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap font-medium">Assigned To:</td>
            <td className="px-6 py-4 whitespace-nowrap">{caseData.assignedToName}</td>
          </tr>
        </tbody>
      </table>
    </section>

    <section className="mb-6">
      <h2 className="text-xl font-semibold mb-2">Policy Violation</h2>
      <div dangerouslySetInnerHTML={{ __html: caseData.violation }} />
    </section>

    <section className="mb-6">
      <h2 className="text-xl font-semibold mb-2">Corrective Action</h2>
      <div dangerouslySetInnerHTML={{ __html: caseData.action }} />
    </section>
  </div>
));

export default CorrectiveAction;