import React, { useState, useEffect, useCallback } from 'react';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { collection, query, where, getDocs, limit, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, FileText, AlertCircle, CheckCircle, Clock, Users, BarChart2, Settings, Briefcase } from 'lucide-react';

const pageName = 'Dashboard';

const Dashboard = () => {
  const { currentCompany } = useAppContext();
  const { user } = useAuth();
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState({ totalCases: 0, openCases: 0, closedCases: 0, inProgressCases: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${pageName} - HR Case Hub`;
  }, []);

  const fetchCases = useCallback(async () => {
    console.log("fetchCases called. Company:", currentCompany?.id, "User:", user?.uid);
    if (currentCompany && user && user.uid) {
      try {
        setLoading(true);
        const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
        console.log("Querying cases collection:", casesRef.path);
        const q = query(
          casesRef,
          where("assignedTo", "==", user.uid),
          limit(10)
        );
        const querySnapshot = await getDocs(q);
        console.log("Query executed. Number of cases found:", querySnapshot.size);
        const casesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Fetched cases:", casesData);

        // Fetch employee names
        const casesWithEmployeeNames = await Promise.all(casesData.map(async (caseItem) => {
          console.log("Processing case:", caseItem.id, "Employee ID:", caseItem.employee);
          if (caseItem.employee) {
            const employeeRef = doc(db, 'companies', currentCompany.id, 'employees', caseItem.employee);
            console.log("Fetching employee document from:", employeeRef.path);
            const employeeDoc = await getDoc(employeeRef);
            if (employeeDoc.exists()) {
              const employeeData = employeeDoc.data();
              console.log("Employee found:", employeeData.firstName, employeeData.lastName);
              return {
                ...caseItem,
                employeeName: `${employeeData.firstName} ${employeeData.lastName}`
              };
            } else {
              console.log("Employee document not found for ID:", caseItem.employee);
            }
          } else {
            console.log("No employee ID found for case:", caseItem.id);
          }
          return {
            ...caseItem,
            employeeName: 'Employee Not Assigned'
          };
        }));

        console.log("Cases with employee names:", casesWithEmployeeNames);
        processCaseData(casesWithEmployeeNames);
      } catch (err) {
        console.error("Error fetching cases:", err);
        setError("Failed to fetch cases: " + err.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Company or user not available:", { company: !!currentCompany, user: !!user, userId: user?.uid });
      setLoading(false);
    }
  }, [currentCompany, user]);

  useEffect(() => {
    console.log("Dashboard useEffect triggered. CurrentCompany:", !!currentCompany, "User:", !!user, "User UID:", user?.uid);
    if (currentCompany && user && user.uid) {
      console.log("Calling fetchCases");
      fetchCases();
    } else {
      console.log("Not calling fetchCases. Missing data:", { currentCompany: !currentCompany, user: !user, userId: !user?.uid });
      setLoading(false);
    }
  }, [currentCompany, user, fetchCases]);

  const processCaseData = (casesData) => {
    const sortedCases = casesData.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
    const open = sortedCases.filter(c => c.status === 'Open').length;
    const closed = sortedCases.filter(c => c.status === 'Closed').length;
    const inProgress = sortedCases.filter(c => c.status === 'In Progress').length;
    setMetrics({ 
      totalCases: sortedCases.length, 
      openCases: open, 
      closedCases: closed,
      inProgressCases: inProgress
    });
    setCases(sortedCases);
  };

  if (!currentCompany || !user) {
    return <div className="flex justify-center items-center h-screen">Loading user and company data...</div>;
  }

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading cases...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const MetricCard = ({ title, value, icon: Icon, color }) => (
    <div className={`bg-${color}-100 rounded-lg shadow-md p-6 flex items-center`}>
      <div className={`rounded-full bg-${color}-200 p-3 mr-4`}>
        <Icon className={`h-8 w-8 text-${color}-600`} />
      </div>
      <div>
        <h3 className={`text-lg font-semibold text-${color}-800`}>{title}</h3>
        <p className={`text-2xl font-bold text-${color}-900`}>{value}</p>
      </div>
    </div>
  );

  const QuickLinkCard = ({ title, icon: Icon, path, color }) => (
    <Link to={path} className={`bg-white rounded-lg shadow-md p-4 flex items-center hover:bg-${color}-50 transition-colors duration-200`}>
      <div className={`rounded-full bg-${color}-100 p-3 mr-4`}>
        <Icon className={`h-6 w-6 text-${color}-600`} />
      </div>
      <span className={`text-lg font-medium text-${color}-800`}>{title}</span>
    </Link>
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'Open':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Pending Review':
        return 'bg-yellow-100 text-yellow-800';
      case 'Closed':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="p-4 sm:p-6 bg-gray-100">
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6 sm:mb-8">Welcome, {user.firstName}!</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-6 sm:mb-8">
        <MetricCard title="Total Cases" value={metrics.totalCases} icon={FileText} color="blue" />
        <MetricCard title="Open Cases" value={metrics.openCases} icon={AlertCircle} color="yellow" />
        <MetricCard title="In Progress" value={metrics.inProgressCases} icon={Clock} color="orange" />
        <MetricCard title="Closed Cases" value={metrics.closedCases} icon={CheckCircle} color="green" />
      </div>

      <div className="mb-6 sm:mb-8">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Quick Links</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
          <QuickLinkCard title="New Case" icon={Plus} path="/cases/add" color="indigo" />
          <QuickLinkCard title="All Cases" icon={FileText} path="/cases" color="blue" />
          <QuickLinkCard title="Employees" icon={Users} path="/employees" color="green" />
          <QuickLinkCard title="Reports" icon={BarChart2} path="/reports" color="purple" />
          <QuickLinkCard title="Settings" icon={Settings} path="/settings" color="gray" />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
          <Briefcase className="mr-2 text-indigo-600" />
          Your Cases
        </h2>
        {cases.length === 0 ? (
          <p className="text-gray-500">You currently have no cases assigned to you.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee</th>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cases.map((caseItem) => (
                  <tr key={caseItem.id} className="hover:bg-gray-50 cursor-pointer" onClick={() => navigate(`/cases/${caseItem.id}`)}>
                    <td className="px-3 py-4 whitespace-nowrap text-sm">{caseItem.referenceId}</td>
                    <td className="px-3 py-4 whitespace-nowrap text-sm">{caseItem.employeeName}</td>
                    <td className="px-3 py-4 whitespace-nowrap text-sm">{caseItem.type}</td>
                    <td className="px-3 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(caseItem.status)}`}>
                        {caseItem.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;


