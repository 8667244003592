import React from 'react';

const AssignedToDropdown = React.memo(({ value, onChange, users, name }) => {
  const handleChange = (e) => {
    onChange({
      target: {
        name: name,
        value: e.target.value
      }
    });
  };

  return (
    <select
      value={value}
      onChange={handleChange}
      name={name}
      className="w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
    >
      <option value="">Select Assigned To</option>
      {users.map((user) => (
        <option key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
      ))}
    </select>
  );
});

export default AssignedToDropdown;