import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { 
  Home,
  FileText,
  Users,
  Settings,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  ChevronLeft,
  BarChart2,
  PieChart,
  CheckCircle,
  X,
  FolderPlus,
  UserPlus,
  FileBarChart,
  Activity,
  Building // New import for the Employees icon
} from 'lucide-react';
import { usePermissions } from './contexts/PermissionsContext';

const Sidebar = ({ isMobile, onClose, isSidebarOpen }) => {
  const { user } = useAuth();
  const { checkPermission } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const [expandedMenus, setExpandedMenus] = useState({});
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleMenu = useCallback((menuName) => {
    setExpandedMenus(prevState => ({
      ...prevState,
      [menuName]: !prevState[menuName]
    }));
  }, []);

  const isExactActive = useCallback((path) => {
    return location.pathname === path;
  }, [location]);

  const isTopLevelActive = useCallback((path, subItems) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    if (subItems) {
      return subItems.some(subItem => location.pathname.startsWith(subItem.path));
    }
    return location.pathname.startsWith(path);
  }, [location]);

  const handleItemClick = (item) => {
    if (!isExpanded) {
      setIsExpanded(true);
    } else if (item.subItems) {
      toggleMenu(item.name);
    } else if (!item.subItems) {
      navigate(item.path);
    }
  };

  const iconSize = isExpanded ? 24 : 32;

  const menuItems = useMemo(() => [
    { name: 'Home', icon: Home, path: '/', color: 'text-blue-500' },
    { 
      name: 'Cases', 
      icon: FileText, 
      path: '/cases',
      color: 'text-green-500',
      subItems: [
        { name: 'All Cases', icon: FileText, path: '/cases', color: 'text-green-500' },
        { name: 'Add Case', icon: FolderPlus, path: '/cases/add', color: 'text-green-500' },
      ]
    },
    { 
      name: 'Employees', 
      icon: Building, // Changed from Users to Building
      path: '/employees',
      color: 'text-yellow-500',
      subItems: [
        { name: 'All Employees', icon: Users, path: '/employees', color: 'text-yellow-500' },
        { name: 'Add Employees', icon: UserPlus, path: '/employees/manage', color: 'text-yellow-500' }, // Changed from 'Manage Employees' to 'Add Employees'
      ]
    },
    { 
      name: 'Reports', 
      icon: BarChart2, 
      path: '/reports',
      color: 'text-purple-500',
      subItems: [
        { name: 'Case Summary', icon: FileBarChart, path: '/reports/case-summary', color: 'text-purple-500' },
        { name: 'Case Status', icon: PieChart, path: '/reports/case-status', color: 'text-purple-500' },
        { name: 'Case Outcome', icon: CheckCircle, path: '/reports/case-outcome', color: 'text-purple-500' },
        { name: 'Caseload', icon: Activity, path: '/reports/caseload', color: 'text-purple-500' },
      ]
    },
    ...(user && (user.role === 'admin' || user.role === 'superuser') 
      ? [{ name: 'Settings', icon: Settings, path: '/settings', color: 'text-indigo-500' }]
      : []
    ),
  ], [user]);

  // Ensure the correct top-level menu is expanded on initial render
  useEffect(() => {
    menuItems.forEach(item => {
      if (isTopLevelActive(item.path, item.subItems)) {
        setExpandedMenus(prevState => ({
          ...prevState,
          [item.name]: true
        }));
      }
    });
  }, [menuItems, isTopLevelActive]);

  const sidebarContent = useMemo(() => (
    <ul className="space-y-2">
      {menuItems.map((item) => {
        const isItemActive = isTopLevelActive(item.path, item.subItems);
        return (
          <li key={item.name}>
            <div
              className={`flex items-center p-2 text-base font-normal rounded-lg cursor-pointer ${
                isItemActive ? 'bg-white bg-opacity-10' : 'hover:bg-white hover:bg-opacity-5'
              }`}
              onClick={() => handleItemClick(item)}
            >
              <item.icon className={`${item.color} ${isExpanded ? 'w-6 h-6' : 'w-8 h-8'} transition-all duration-300`} />
              {isExpanded && <span className="ml-3 text-white">{item.name}</span>}
              {isExpanded && item.subItems && (
                <ChevronDown className={`ml-auto ${expandedMenus[item.name] || isItemActive ? 'transform rotate-180' : ''}`} />
              )}
            </div>
            {isExpanded && item.subItems && (expandedMenus[item.name] || isItemActive) && (
              <ul className="pl-4 mt-2 space-y-2">
                {item.subItems.map((subItem) => (
                  <li key={subItem.name}>
                    <NavLink
                      to={subItem.path}
                      className={({ isActive }) =>
                        `flex items-center p-2 text-sm font-normal rounded-lg ${
                          isExactActive(subItem.path) ? 'bg-white bg-opacity-10' : 'hover:bg-white hover:bg-opacity-5'
                        }`
                      }
                    >
                      <subItem.icon className={`${subItem.color} w-5 h-5`} />
                      <span className="ml-3 text-white">{subItem.name}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  ), [menuItems, expandedMenus, isExpanded, isTopLevelActive, isExactActive, handleItemClick]);

  if (!user) {
    return null;
  }

  if (isMobile && !isSidebarOpen) {
    return null;
  }

  return (
    <div className={`${isMobile ? 'fixed inset-y-0 left-0 z-10' : 'relative'} bg-gradient-to-b from-indigo-900 to-indigo-700 text-white ${isExpanded ? 'w-64' : 'w-20'} flex flex-col transition-all duration-300 ease-in-out`}>
      {isMobile && (
        <div className="p-4 flex justify-end">
          <button
            className="text-white hover:text-gray-300 transition-colors duration-200"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>
      )}
      <div className={`flex-1 overflow-y-auto ${isMobile ? 'mt-4' : ''} px-4 py-6`}>
        {sidebarContent}
      </div>
      {!isMobile && (
        <div className="relative w-full h-16">
          <button
            className="absolute top-1/2 left-full transform -translate-x-1/2 -translate-y-1/2 bg-white text-gray-500 p-2 rounded-full shadow-lg hover:bg-gray-100 transition-colors duration-200"
            onClick={toggleExpand}
          >
            {isExpanded ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;