import React, { useState, useCallback, useEffect } from 'react';
import { Plus, Edit, Trash, Users, AlertCircle, User, UserX, Briefcase, HelpCircle } from 'lucide-react';
import EmployeeDropdown from '../components/EmployeeDropdown';
import Lightbox from './Lightbox';

const InvolvedPartiesSection = ({ involvedParties, companyId, onUpdate, readOnly = false }) => {
  const [localInvolvedParties, setLocalInvolvedParties] = useState(involvedParties || []);
  const [editingInvolvedParty, setEditingInvolvedParty] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [newInvolvedParty, setNewInvolvedParty] = useState({ id: '', name: '', role: 'Witness', notes: '' });
  const [editingParty, setEditingParty] = useState(null);

  useEffect(() => {
    setLocalInvolvedParties(involvedParties || []);
  }, [involvedParties]);

  const addInvolvedParty = useCallback(() => {
    setIsLightboxOpen(true);
  }, []);

  const handleSaveNewInvolvedParty = () => {
    const newInvolvedParties = [...localInvolvedParties, newInvolvedParty];
    setLocalInvolvedParties(newInvolvedParties);
    onUpdate(newInvolvedParties);
    setIsLightboxOpen(false);
    setNewInvolvedParty({ id: '', name: '', role: 'Witness', notes: '' });
  };

  const updateInvolvedParty = useCallback((index, updatedParty) => {
    const newInvolvedParties = localInvolvedParties.map((party, i) => 
      i === index ? updatedParty : party
    );
    setLocalInvolvedParties(newInvolvedParties);
    onUpdate(newInvolvedParties);
    setEditingParty(null);
  }, [localInvolvedParties, onUpdate]);

  const deleteInvolvedParty = useCallback((index) => {
    const newInvolvedParties = localInvolvedParties.filter((_, i) => i !== index);
    setLocalInvolvedParties(newInvolvedParties);
    onUpdate(newInvolvedParties);
  }, [localInvolvedParties, onUpdate]);

  const roleOptions = ['Witness', 'Complainant', 'Subject', 'Supervisor', 'HR Representative', 'Other'];

  const getRoleIcon = (role) => {
    switch (role) {
      case 'Witness':
        return <User className="w-5 h-5 text-blue-500" />;
      case 'Complainant':
        return <AlertCircle className="w-5 h-5 text-red-500" />;
      case 'Subject':
        return <UserX className="w-5 h-5 text-yellow-500" />;
      case 'Supervisor':
        return <Users className="w-5 h-5 text-green-500" />;
      case 'HR Representative':
        return <Briefcase className="w-5 h-5 text-purple-500" />;
      default:
        return <HelpCircle className="w-5 h-5 text-gray-500" />;
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 overflow-hidden">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-base sm:text-lg font-medium text-gray-900 flex items-center min-w-0">
          <Users className="w-5 h-5 sm:w-6 sm:h-6 mr-2 flex-shrink-0 text-indigo-500" />
          <span className="truncate">Involved Parties</span>
        </h3>
        {!readOnly && (
          <button
            onClick={addInvolvedParty}
            className="inline-flex items-center px-2 sm:px-3 py-1 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex-shrink-0 ml-2"
          >
            <Plus className="w-4 h-4 sm:w-5 sm:h-5 mr-1" />
            Add Involved Party
          </button>
        )}
      </div>
      {Array.isArray(localInvolvedParties) && localInvolvedParties.length > 0 ? (
        <div className="space-y-3">
          {localInvolvedParties.map((party, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-3 flex items-start overflow-hidden hover:bg-gray-100 transition-colors duration-300">
              <div className="flex-shrink-0 mr-3 mt-1">
                {getRoleIcon(party.role)}
              </div>
              <div className="min-w-0 flex-grow">
                <div className="flex items-center mb-1">
                  <p className="text-sm font-medium text-gray-900">{party.name || 'Not selected'}</p>
                  <p className="text-sm font-medium text-indigo-600 ml-2">{party.role}</p>
                </div>
                {party.notes && <p className="text-sm text-gray-600 break-words">{party.notes}</p>}
              </div>
              {!readOnly && (
                <div className="flex space-x-2 flex-shrink-0 ml-2">
                  <button
                    onClick={() => {
                      setEditingParty(index);
                      setNewInvolvedParty(localInvolvedParties[index]);
                    }}
                    className="text-gray-400 hover:text-indigo-600 transition-colors duration-300"
                  >
                    <Edit className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => deleteInvolvedParty(index)}
                    className="text-gray-400 hover:text-red-600 transition-colors duration-300"
                  >
                    <Trash className="w-5 h-5" />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-center text-indigo-400">
            <Users className="w-5 h-5 mr-2" />
            <h3 className="text-sm font-medium text-gray-900">No involved parties yet</h3>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {readOnly
              ? "There are no involved parties to display."
              : "Click 'Add Involved Party' to get started."}
          </p>
        </div>
      )}

      <Lightbox
        isOpen={isLightboxOpen || editingParty !== null}
        onClose={() => {
          setIsLightboxOpen(false);
          setEditingParty(null);
          setNewInvolvedParty({ id: '', name: '', role: 'Witness', notes: '' });
        }}
        title={editingParty !== null ? "Edit Involved Party" : "Add Involved Party"}
      >
        <form onSubmit={(e) => { 
          e.preventDefault(); 
          if (editingParty !== null) {
            updateInvolvedParty(editingParty, newInvolvedParty);
          } else {
            handleSaveNewInvolvedParty();
          }
        }}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Employee <span className="text-red-500">*</span>
            </label>
            <EmployeeDropdown
              value={newInvolvedParty.id}
              onChange={(e) => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                setNewInvolvedParty(prev => ({ ...prev, id: e.target.value, name: selectedOption.text }));
              }}
              companyId={companyId}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Role <span className="text-red-500">*</span>
            </label>
            <select
              value={newInvolvedParty.role}
              onChange={(e) => setNewInvolvedParty(prev => ({ ...prev, role: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            >
              <option value="">Select a role</option>
              {roleOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Notes</label>
            <input
              type="text"
              value={newInvolvedParty.notes}
              onChange={(e) => setNewInvolvedParty(prev => ({ ...prev, notes: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {editingParty !== null ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </Lightbox>
    </div>
  );
};

export default InvolvedPartiesSection;