import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from './AppContext';
import { FileText, BarChart2, CheckCircle, Users } from 'lucide-react';

const pageName = 'Reports';

const ReportsPage = () => {
  const navigate = useNavigate();
  const { currentCompany } = useAppContext();

  const reports = [
    {
      id: 'case-summary',
      name: 'Case Summary Report',
      icon: <FileText className="h-6 w-6" />,
      description: 'Provides an overview of all cases, including key details such as case type, status, assigned staff, and duration.'
    },
    {
      id: 'case-status',
      name: 'Case Status Report',
      icon: <BarChart2 className="h-6 w-6" />,
      description: 'Shows the distribution of cases across different status categories, including a breakdown by case type and department.'
    },
    {
      id: 'case-outcome',
      name: 'Case Outcome Report',
      icon: <CheckCircle className="h-6 w-6" />,
      description: 'Summarizes the outcomes of closed cases, including resolutions, actions taken, and resulting policy changes.'
    },
    {
      id: 'employee-involvement',
      name: 'Employee Involvement Report',
      icon: <Users className="h-6 w-6" />,
      description: 'Focuses on employee participation in HR cases, showing frequency of involvement and patterns across departments or teams.'
    }
  ];

  const runReport = (reportId) => {
    if (currentCompany) {
      navigate(`/reports/${reportId}`);
    } else {
      alert('Please select a company to run reports.');
    }
  };

  useEffect(() => {
    document.title = `${pageName} - HR Case Hub`;
  }, []);

  if (!currentCompany) {
    return <div className="p-6 bg-gray-100">Please select a company to view reports.</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Reports for {currentCompany.name}</h1>
      <div className="grid grid-cols-2 gap-6">
        {reports.map((report) => (
          <div key={report.id} className="bg-white p-4 rounded-lg shadow-md flex flex-col h-full">
            <div className="flex items-center mb-2">
              <div className="p-2 bg-blue-100 rounded-full mr-3">
                {report.icon}
              </div>
              <h2 className="text-lg font-semibold text-gray-800">{report.name}</h2>
            </div>
            <p className="text-sm text-gray-600 mb-4 flex-grow">{report.description}</p>
            <button
              onClick={() => runReport(report.id)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out mt-auto"
            >
              Run Report
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsPage;