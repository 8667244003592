import React from 'react';

const EditableUserTable = ({ data, setData, validateData }) => {
  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {Object.keys(data[0]).map((header) => (
              <th
                key={header}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).map((field) => (
                <td key={field} className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={row[field]}
                    onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!validateData(data) && (
        <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          Some entries in the table are invalid. Please correct them.
        </div>
      )}
    </div>
  );
};

export default EditableUserTable;