import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { useAppContext } from './AppContext';
import { Bell, LogOut, Building, Menu } from 'lucide-react';
import { Link } from 'react-router-dom';
import logo from './logo.png'; // Add this import at the top of the file

const Header = ({ isMobile, toggleSidebar }) => {
  const { user, logout } = useAuth();
  const { currentCompany } = useAppContext();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  if (!user) {
    return null;
  }

  const userInitials = user.name
    ? user.name.split(' ').map(n => n[0]).join('').toUpperCase()
    : user.email.substring(0, 2).toUpperCase();

  return (
    <header className="bg-white shadow-md">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {isMobile && (
              <button
                onClick={toggleSidebar}
                className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <Menu className="h-6 w-6" />
              </button>
            )}
            <img src={logo} alt="HR Case Hub" className="h-8 w-auto ml-2" /> {/* Replace h1 with this img tag */}
            {!isMobile && currentCompany && (
              <div className="ml-4 flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                <Building className="h-4 w-4 mr-2" />
                {currentCompany.name}
              </div>
            )}
          </div>
          <div className="flex items-center">
            {!isMobile && (
              <button className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2">
                <Bell className="h-6 w-6" />
              </button>
            )}
            <div className="relative">
              <button
                onClick={() => setUserMenuOpen(!userMenuOpen)}
                className="w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {userInitials}
              </button>
              {userMenuOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-auto min-w-[200px] rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
                    {user.email}
                  </div>
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Profile
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sign out
                  </button>
                </div>
              )}
            </div>
            {isMobile && (
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="ml-2 p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <Bell className="h-6 w-6" />
              </button>
            )}
          </div>
        </div>
      </div>
      {mobileMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {currentCompany && (
              <div className="flex items-center bg-blue-100 text-blue-800 px-3 py-2 rounded-md text-sm font-medium">
                <Building className="h-4 w-4 mr-2" />
                {currentCompany.name}
              </div>
            )}
            <Link
              to="/profile"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Profile
            </Link>
            <button
              onClick={handleLogout}
              className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default React.memo(Header);