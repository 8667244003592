import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useAuth } from './AuthContext';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { user: authUser, loading: authLoading } = useAuth();
  const [currentCompany, setCurrentCompany] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const fetchCompanyData = useCallback(async (companyId) => {
    console.log('Fetching company data for ID:', companyId);
    
    const cachedCompanyData = localStorage.getItem(`companyData_${companyId}`);
    const cacheTimestamp = localStorage.getItem(`companyData_${companyId}_timestamp`);
    const isValidCache = cacheTimestamp && (Date.now() - parseInt(cacheTimestamp, 10)) < 3600000; // 1 hour cache

    if (cachedCompanyData && isValidCache) {
      console.log('Using cached company data');
      return JSON.parse(cachedCompanyData);
    }

    try {
      const companyDoc = await getDoc(doc(db, 'companies', companyId));
      if (companyDoc.exists()) {
        const companyData = { id: companyId, ...companyDoc.data() };
        localStorage.setItem(`companyData_${companyId}`, JSON.stringify(companyData));
        localStorage.setItem(`companyData_${companyId}_timestamp`, Date.now().toString());
        return companyData;
      } else {
        console.error('Company document does not exist');
        return null;
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (authLoading) {
        console.log("Auth is still loading");
        return;
      }

      if (!authUser) {
        console.log("No authenticated user");
        setLoading(false);
        setIsInitialized(true);
        return;
      }

      console.log("Fetching user data for:", authUser.uid);
      try {
        const userDoc = await getDoc(doc(db, 'users', authUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("User data fetched:", userData);
          setUser({ ...authUser, ...userData });
          
          if (userData.companyId) {
            const companyData = await fetchCompanyData(userData.companyId);
            if (companyData) {
              setCurrentCompany(companyData);
            }
          }
        } else {
          console.log('User document does not exist yet. This is expected for new users.');
          setUser(authUser); // Set the basic auth user info
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data. Please try again.');
      } finally {
        setLoading(false);
        setIsInitialized(true);
      }
    };

    fetchUserData();
  }, [authUser, authLoading, fetchCompanyData]);

  const value = useMemo(() => ({
    user,
    setUser,
    currentCompany,
    setCurrentCompany,
    loading,
    error,
    isInitialized
  }), [user, currentCompany, loading, error, isInitialized]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);