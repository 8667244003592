import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { collection, query, getDocs, doc, getDoc, where, writeBatch } from 'firebase/firestore';
import { format, parseISO } from 'date-fns';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { db } from '../firebase';
import { useAppContext } from '../AppContext';

import { 
  ArrowUpDown, 
  FileText, 
  Users, 
  Calendar, 
  Tag, 
  CheckCircle, 
  User, 
  Briefcase, 
  Settings, 
  Mail, 
  CheckSquare, 
  Square,
  Filter, 
  Download, 
  FileSpreadsheet, 
  File,
  Plus,
  X,
  ClipboardList
} from 'lucide-react';

const CaseSummaryReport = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentCompany } = useAppContext();
  const [filters, setFilters] = useState({0: {field: '', value: ''}});
  const [openFilter, setOpenFilter] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedColumns, setSelectedColumns] = useState([
    'referenceId', 'type', 'status', 'assignedStaff', 'employeeName', 'department', 'createdOn'
  ]);
  const reportRef = useRef();

  const columns = [
    { id: 'referenceId', name: 'Reference ID', icon: <FileText className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'type', name: 'Type', icon: <Tag className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'status', name: 'Status', icon: <CheckCircle className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'assignedStaff', name: 'Assigned Staff', icon: <User className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'employeeName', name: 'Employee Name', icon: <Users className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'department', name: 'Department', icon: <Briefcase className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'position', name: 'Position', icon: <Briefcase className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'email', name: 'Email', icon: <Mail className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'hireDate', name: 'Hire Date', icon: <Calendar className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'gender', name: 'Gender', icon: <User className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'ethnicity', name: 'Ethnicity', icon: <Users className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'age', name: 'Age', icon: <User className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'disabilityStatus', name: 'Disability Status', icon: <User className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'veteranStatus', name: 'Veteran Status', icon: <User className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'createdOn', name: 'Created On', icon: <Calendar className="h-5 w-5 text-gray-500" />, sortable: true },
  ];

  const fetchCases = useCallback(async () => {
    if (currentCompany) {
      try {
        console.log('Fetching cases for company:', currentCompany.id);
        const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
        const querySnapshot = await getDocs(casesRef);
        console.log('Number of cases found:', querySnapshot.size);

        const cases = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Collect unique employee and user IDs
        const employeeIds = new Set();
        const userIds = new Set();
        cases.forEach(caseData => {
          if (caseData.employee) employeeIds.add(caseData.employee);
          if (caseData.assignedTo) userIds.add(caseData.assignedTo);
        });

        // Fetch employee data in batches
        const employeeData = await fetchBatchData(
          'companies', currentCompany.id, 'employees', 
          Array.from(employeeIds)
        );

        // Fetch user data in batches
        const userData = await fetchBatchData('users', null, null, Array.from(userIds));

        // Process cases with fetched data
        const processedCases = cases.map(caseData => {
          const employee = employeeData[caseData.employee];
          const user = userData[caseData.assignedTo];

          return {
            ...caseData,
            employeeName: employee ? `${employee.firstName} ${employee.lastName}` : 'N/A',
            department: employee?.department || 'N/A',
            position: employee?.position || 'N/A',
            email: employee?.email || 'N/A',
            hireDate: employee?.hireDate || 'N/A',
            gender: employee?.gender || 'N/A',
            ethnicity: employee?.ethnicity || 'N/A',
            age: employee?.age || 'N/A',
            disabilityStatus: employee?.disabilityStatus || 'N/A',
            veteranStatus: employee?.veteranStatus || 'N/A',
            assignedStaff: user ? `${user.firstName} ${user.lastName}` : 'Unknown',
            createdOn: formatCreatedAt(caseData.createdAt)
          };
        });

        console.log('Processed cases:', processedCases);
        setData(processedCases);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cases:", error);
        setError("Failed to fetch report data. Please try again.");
        setLoading(false);
      }
    }
  }, [currentCompany]);

  // Helper function to fetch data in batches
  const fetchBatchData = async (collectionName, companyId, subCollectionName, ids) => {
    const batchSize = 10; // Adjust based on your Firestore plan and requirements
    const batches = [];

    for (let i = 0; i < ids.length; i += batchSize) {
      const batch = ids.slice(i, i + batchSize);
      let ref = collection(db, collectionName);
      if (companyId && subCollectionName) {
        ref = collection(db, collectionName, companyId, subCollectionName);
      }
      const q = query(ref, where('__name__', 'in', batch));
      batches.push(getDocs(q));
    }

    const results = await Promise.all(batches);
    const data = {};
    results.forEach(querySnapshot => {
      querySnapshot.forEach(doc => {
        data[doc.id] = doc.data();
      });
    });

    return data;
  };

  // Helper function to format createdAt
  const formatCreatedAt = (createdAt) => {
    if (!createdAt) return 'N/A';
    
    let dateValue;
    if (typeof createdAt === 'string') {
      dateValue = parseISO(createdAt);
    } else if (createdAt instanceof Date) {
      dateValue = createdAt;
    } else if (createdAt.toDate && typeof createdAt.toDate === 'function') {
      dateValue = createdAt.toDate();
    } else {
      console.log('Unexpected createdAt format:', createdAt);
      return 'N/A';
    }
    return format(dateValue, 'MMM d, yyyy');
  };

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Open':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Pending Review':
        return 'bg-yellow-100 text-yellow-800';
      case 'Closed':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const addFilter = () => {
    const newKey = Object.keys(filters).length;
    setFilters({...filters, [newKey]: {field: '', value: ''}});
  };

  const removeFilter = (key) => {
    const newFilters = {...filters};
    delete newFilters[key];
    setFilters(newFilters);
  };

  const handleFilterFieldChange = (key, field) => {
    setFilters({...filters, [key]: {...filters[key], field}});
  };

  const handleFilterValueChange = (key, value) => {
    setFilters({...filters, [key]: {...filters[key], value}});
  };

  const handleSort = (columnId) => {
    if (sortColumn === columnId) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnId);
      setSortDirection('asc');
    }
  };

  const toggleColumn = (columnId) => {
    setSelectedColumns(prev => 
      prev.includes(columnId) 
        ? prev.filter(id => id !== columnId)
        : [...prev, columnId]
    );
  };

  const [selectAllColumns, setSelectAllColumns] = useState(true);

  const toggleAllColumns = () => {
    if (selectAllColumns) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(columns.map(col => col.id));
    }
    setSelectAllColumns(!selectAllColumns);
  };

  const filteredAndSortedData = useMemo(() => {
    if (!data) return [];

    let result = data.filter(row => {
      return Object.values(filters).every(({field, value}) => {
        if (!field || !value) return true;
        const rowValue = row[field];
        if (rowValue === null || rowValue === undefined) return false;
        return String(rowValue).toLowerCase().includes(String(value).toLowerCase());
      });
    });

    if (sortColumn) {
      result.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue === bValue) return 0;
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;
        return aValue < bValue ? (sortDirection === 'asc' ? -1 : 1) : (sortDirection === 'asc' ? 1 : -1);
      });
    }

    return result;
  }, [data, filters, sortColumn, sortDirection]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredAndSortedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cases");
    XLSX.writeFile(workbook, "case_summary_report.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4'
    });

    const tableColumns = columns
      .filter(col => selectedColumns.includes(col.id))
      .map(col => col.name);

    const tableData = filteredAndSortedData.map(row => 
      columns
        .filter(col => selectedColumns.includes(col.id))
        .map(col => row[col.id])
    );

    doc.autoTable({
      head: [tableColumns],
      body: tableData,
      startY: 60,
      margin: { top: 60 },
      styles: { overflow: 'linebreak', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } },
      theme: 'striped',
      headStyles: { fillColor: [66, 139, 202] },
    });

    // Add title to the document
    doc.setFontSize(18);
    doc.text('Case Summary Report', 40, 40);

    doc.save("case_summary_report.pdf");
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!data || data.length === 0) return <div>No data available</div>;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl" ref={reportRef}>
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center">
            <ClipboardList className="mr-2" size={24} />
            Case Summary Report
          </h1>
        </div>
      </div>

      <div className="bg-white rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between mb-4">
            <div className="flex space-x-2">
              <div className="relative inline-block text-left">
                <button
                  onClick={() => setOpenFilter(openFilter ? null : 'main')}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <Filter className="h-5 w-5 mr-2 text-indigo-500" />
                  Filters
                </button>
                {openFilter === 'main' && (
                  <div className="origin-top-left absolute left-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    {Object.keys(filters).map((key, index) => (
                      <div key={index} className="px-4 py-3 flex items-center space-x-2">
                        <select
                          value={filters[key].field || ''}
                          onChange={(e) => handleFilterFieldChange(key, e.target.value)}
                          className="block w-1/3 border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                        >
                          <option value="">Select field</option>
                          {columns.map((column) => (
                            <option key={column.id} value={column.id}>{column.name}</option>
                          ))}
                        </select>
                        <input
                          type="text"
                          value={filters[key].value || ''}
                          onChange={(e) => handleFilterValueChange(key, e.target.value)}
                          placeholder="Filter value"
                          className="block w-1/2 border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                        />
                        <button
                          onClick={() => removeFilter(key)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <X className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                    <div className="px-4 py-3">
                      <button
                        onClick={addFilter}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <Plus className="h-4 w-4 mr-1" />
                        Add Filter
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="relative inline-block text-left">
                <button
                  onClick={() => setOpenFilter(openFilter === 'columns' ? null : 'columns')}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <Settings className="h-5 w-5 mr-2 text-indigo-500" />
                  Columns
                </button>
                {openFilter === 'columns' && (
                  <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div className="px-4 py-3">
                      <p className="text-sm font-medium text-gray-700">Select Columns</p>
                      <div className="flex items-center mt-2">
                        <button
                          onClick={toggleAllColumns}
                          className="flex items-center text-sm text-gray-700 hover:text-indigo-600"
                        >
                          {selectAllColumns ? (
                            <CheckSquare className="h-5 w-5 mr-2" />
                          ) : (
                            <Square className="h-5 w-5 mr-2" />
                          )}
                          {selectAllColumns ? 'Unselect All' : 'Select All'}
                        </button>
                      </div>
                      {columns.map((column) => (
                        <div key={column.id} className="flex items-center mt-2">
                          <input
                            type="checkbox"
                            id={column.id}
                            checked={selectedColumns.includes(column.id)}
                            onChange={() => toggleColumn(column.id)}
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          />
                          <label htmlFor={column.id} className="ml-2 text-sm text-gray-900">
                            {column.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="relative inline-block text-left">
                <button
                  onClick={() => setOpenFilter(openFilter === 'sort' ? null : 'sort')}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <ArrowUpDown className="h-5 w-5 mr-2 text-indigo-500" />
                  Sort
                </button>
                {openFilter === 'sort' && (
                  <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    {columns.filter(col => col.sortable).map((column) => (
                      <button
                        key={column.id}
                        className={`${sortColumn === column.id && sortDirection === 'asc' ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm w-full`}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.icon}
                        <span className="ml-2">{column.name}</span>
                        {sortColumn === column.id && (
                          <ArrowUpDown className="ml-auto h-5 w-5 text-indigo-500" />
                        )}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="flex space-x-2">
              <button
                onClick={exportToExcel}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <FileSpreadsheet className="h-5 w-5 mr-2 text-gray-500" />
                Export to Excel
              </button>
              <button
                onClick={exportToPDF}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <File className="h-5 w-5 mr-2 text-gray-500" />
                Export to PDF
              </button>
            </div>
          </div>

          <div className="overflow-x-auto custom-scrollbar">
            <table className="min-w-full divide-y divide-gray-200 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  {columns.filter(col => selectedColumns.includes(col.id)).map((column) => (
                    <th key={column.id} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      <div className="flex items-center">
                        {column.icon}
                        <span className="ml-2">{column.name}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredAndSortedData.map((row) => (
                  <tr key={row.id}>
                    {columns.filter(col => selectedColumns.includes(col.id)).map(column => (
                      <td key={column.id} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" style={{ minWidth: '100px' }}>
                        {column.id === 'status' ? (
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(row[column.id])}`}>
                            {row[column.id]}
                          </span>
                        ) : column.id === 'disposition' ? (
                          row[column.id] ? (
                            row[column.id].includes('Dispositions') ? (
                              <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                {row[column.id]}
                              </span>
                            ) : (
                              row[column.id]
                            )
                          ) : (
                            'No Disposition'
                          )
                        ) : (
                          row[column.id]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <style>{`
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #9CA3AF #F3F4F6;
        }
        .custom-scrollbar::-webkit-scrollbar {
          width: 12px;
          height: 12px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #F3F4F6;
          border-radius: 20px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #9CA3AF;
          border-radius: 20px;
          border: 3px solid #F3F4F6;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: #6B7280;
        }
      `}</style>

      <style global>{`
        @media print {
          body {
            background-color: #FFFFFF;
          }
          /* Hide non-essential elements when printing */
          button, .bg-gradient-to-r {
            display: none !important;
          }
        }
      `}</style>
    </div>
  );
};

export default CaseSummaryReport;