import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAppContext } from '../AppContext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CaseOutcomeReportPage = () => {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentCompany } = useAppContext();

  const fetchCases = useCallback(async () => {
    if (currentCompany) {
      try {
        const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
        const q = query(casesRef, where("status", "==", "Closed"));
        const querySnapshot = await getDocs(q);
        const cases = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const processedData = processCaseOutcomeData(cases);
        setReportData(processedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cases:", error);
        setError("Failed to fetch report data. Please try again.");
        setLoading(false);
      }
    }
  }, [currentCompany]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const processCaseOutcomeData = (cases) => {
    const outcomeCounts = cases.reduce((acc, c) => {
      acc[c.resolution] = (acc[c.resolution] || 0) + 1;
      return acc;
    }, {});
    
    const labels = Object.keys(outcomeCounts);
    const data = Object.values(outcomeCounts);

    return {
      labels,
      datasets: [
        {
          label: 'Number of Cases',
          data,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!reportData) return <div>No data available</div>;

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Case Outcome Report</h1>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="h-96">
          <Bar 
            data={reportData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Number of Cases'
                  }
                },
                x: {
                  title: {
                    display: true,
                    text: 'Resolution'
                  }
                }
              }
            }}
          />
        </div>
        <div className="mt-6">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Resolution</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Count</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {reportData.labels.map((label, index) => (
                <tr key={label}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{label}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{reportData.datasets[0].data[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CaseOutcomeReportPage;