import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const EmployeeDropdown = ({ value, onChange, companyId, required, name }) => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (!companyId) return;
      const employeesRef = collection(db, 'companies', companyId, 'employees');
      const snapshot = await getDocs(employeesRef);
      const employeesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeesData);
    };

    fetchEmployees();
  }, [companyId]);

  const handleChange = (e) => {
    onChange({
      target: {
        name: name,
        value: e.target.value
      }
    });
  };

  return (
    <select
      value={value}
      onChange={handleChange}
      className="w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
      required={required}
      name={name}
    >
      <option value="">Select an employee</option>
      {employees && employees.length > 0 ? (
        employees.map(employee => (
          <option key={employee.id} value={employee.id}>
            {`${employee.firstName} ${employee.lastName}`}
          </option>
        ))
      ) : (
        <option disabled>No employees available</option>
      )}
    </select>
  );
};

export default EmployeeDropdown;