export const defaultRoles = {
  admin: {
    name: 'Admin',
    permissions: ['manage_users', 'manage_cases', 'view_reports', 'manage_settings']
  },
  manager: {
    name: 'Manager',
    permissions: ['manage_cases', 'view_reports']
  },
  user: {
    name: 'User',
    permissions: ['view_cases', 'edit_cases']
  }
};