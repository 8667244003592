import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CheckCircle } from 'lucide-react'; // Assuming you're using lucide-react for icons

const SuccessPage = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get('session_id');
    if (sessionId) {
      console.log('Fetching session data for sessionId:', sessionId);
      const functions = getFunctions();
      const getCheckoutSession = httpsCallable(functions, 'getCheckoutSession');
      
      getCheckoutSession({ sessionId })
        .then(result => {
          console.log('Session data received:', result.data);
          setSession(result.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching session data:', error);
          setError(error.message);
          setLoading(false);
        });
    } else {
      console.error('No session_id found in URL');
      setError('No session ID provided');
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (session) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            const navigationState = { 
              email: session.customer_details.email,
              name: session.customer_details.name,
              session_id: new URLSearchParams(location.search).get('session_id')
            };
            console.log('Attempting to navigate to /account-setup with state:', navigationState);
            navigate('/account-setup', { state: navigationState });
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [session, navigate, location]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white shadow-lg rounded-lg">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4 text-xl font-semibold text-center">Loading... Please wait.</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white shadow-lg rounded-lg">
          <p className="text-xl font-semibold text-red-600 text-center">Error: {error}</p>
          <p className="mt-2 text-center">Please try again or contact support.</p>
        </div>
      </div>
    );
  }

  if (!session) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white shadow-lg rounded-lg">
          <p className="text-xl font-semibold text-center">No session found.</p>
          <p className="mt-2 text-center">Please try again or contact support.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white shadow-lg rounded-lg text-center">
        <CheckCircle className="w-16 h-16 text-green-500 mx-auto" />
        <h1 className="mt-4 text-2xl font-bold">Thank you for your purchase!</h1>
        <p className="mt-2">Your email: {session.customer_details.email}</p>
        <p className="mt-4 text-sm text-gray-600">
          Redirecting to account setup in <span className="font-bold">{countdown}</span> seconds...
        </p>
      </div>
    </div>
  );
};

export default SuccessPage;