import React, { useState, useEffect } from 'react';

const EditableEmployeeTable = ({ data, setData, validateData, onRetry, onCancel }) => {
  const [errors, setErrors] = useState({});

  const genderOptions = ['Male', 'Female', 'Non-Binary', 'Prefer not to say'];
  const ethnicityOptions = [
    'African American', 'Asian', 'Caucasian', 'Hispanic', 'Native American',
    'Pacific Islander', 'Two or More Races', 'Other', 'Prefer not to say'
  ];
  const disabilityStatusOptions = ['No disability', 'Has disability', 'Prefer not to say'];
  const veteranStatusOptions = ['Veteran', 'Non-veteran', 'Prefer not to say'];

  useEffect(() => {
    validateTableData();
  }, [data]);

  const validateTableData = () => {
    const newErrors = {};
    data.forEach((row, index) => {
      const rowErrors = {};
      Object.entries(row).forEach(([field, value]) => {
        if (value === null || value === undefined) {
          rowErrors[field] = 'This field is required';
        } else if (typeof value === 'string') {
          if (value.trim() === '') {
            rowErrors[field] = 'This field is required';
          } else if (field === 'Email' && !isValidEmail(value)) {
            rowErrors[field] = 'Invalid email format';
          } else if (field === 'Hire Date' && !isValidDate(value)) {
            rowErrors[field] = 'Invalid date format (YYYY-MM-DD)';
          }
        } else if (field === 'Age') {
          if (!isValidAge(value)) {
            rowErrors[field] = 'Age must be a number between 18 and 100';
          }
        } else {
          rowErrors[field] = 'Invalid value type';
        }
      });
      if (Object.keys(rowErrors).length > 0) {
        newErrors[index] = rowErrors;
      }
    });
    setErrors(newErrors);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidAge = (age) => {
    const numAge = parseInt(age, 10);
    return !isNaN(numAge) && numAge >= 18 && numAge <= 100;
  };

  const isValidDate = (date) => {
    return /^\d{4}-\d{2}-\d{2}$/.test(date);
  };

  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  const renderInput = (rowIndex, field, value) => {
    switch (field) {
      case 'Gender':
        return (
          <select
            value={value}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          >
            {genderOptions.map(option => <option key={option} value={option}>{option}</option>)}
          </select>
        );
      case 'Ethnicity':
        return (
          <select
            value={value}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          >
            {ethnicityOptions.map(option => <option key={option} value={option}>{option}</option>)}
          </select>
        );
      case 'Disability Status':
        return (
          <select
            value={value}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          >
            {disabilityStatusOptions.map(option => <option key={option} value={option}>{option}</option>)}
          </select>
        );
      case 'Veteran Status':
        return (
          <select
            value={value}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          >
            {veteranStatusOptions.map(option => <option key={option} value={option}>{option}</option>)}
          </select>
        );
      case 'Hire Date':
        return (
          <input
            type="date"
            value={value}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        );
      case 'Date of Birth':
        return (
          <input
            type="date"
            value={value}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        );
      default:
        return (
          <input
            type="text"
            value={value}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        );
    }
  };

  return (
    <div>
      {data.some(row => row.isDuplicate) && (
        <div className="mb-4 p-2 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded">
          <p>Rows highlighted in yellow indicate potential duplicate entries based on email address.</p>
        </div>
      )}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {Object.keys(data[0]).filter(key => key !== 'isDuplicate').map((header) => (
              <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className={row.isDuplicate ? 'bg-yellow-100' : ''}>
              {Object.entries(row).filter(([key]) => key !== 'isDuplicate').map(([field, value], cellIndex) => (
                <td key={cellIndex} className="px-6 py-4 whitespace-nowrap">
                  {renderInput(rowIndex, field, value)}
                  {errors[rowIndex]?.[field] && (
                    <p className="mt-2 text-sm text-red-600">{errors[rowIndex][field]}</p>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex justify-end space-x-4">
        <button
          onClick={onCancel}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Cancel Import
        </button>
        <button
          onClick={onRetry}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Retry Import
        </button>
      </div>
    </div>
  );
};

export default EditableEmployeeTable;