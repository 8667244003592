import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, writeBatch, updateDoc } from 'firebase/firestore';
import { auth, db } from './firebase';
import { defaultRoles } from './utils/defaultRoles';
import { Building, Check, AlertCircle, Loader, Mail, User, Briefcase, Lock } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getSubscriptionDetails } from './utils/stripe';
import { useAuth } from './AuthContext';
import { useAppContext } from './AppContext';

const AccountSetupPage = () => {
  const { login } = useAuth();
  const { user } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    companyAbbreviation: '',
    companySize: '',
    industry: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  const industryOptions = [
    'Technology', 'Healthcare', 'Finance', 'Education', 'Manufacturing',
    'Retail', 'Hospitality', 'Construction', 'Agriculture', 'Transportation',
    'Energy', 'Media', 'Telecommunications', 'Real Estate', 'Other'
  ];

  useEffect(() => {
    document.title = "Account Setup - HR Case Hub";
  }, []);

  useEffect(() => {
    if (user && user.hasCompletedSetup) {
      navigate('/dashboard');
    }

    const { email, name, session_id } = location.state || {};
    if (email) setFormData(prev => ({ ...prev, email }));
    if (name) {
      const [firstName, ...lastNameParts] = name.split(' ');
      setFormData(prev => ({
        ...prev,
        firstName: firstName || '',
        lastName: lastNameParts.join(' ') || ''
      }));
    }
    if (!session_id) setError('Invalid session. Please try again.');
  }, [user, navigate, location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (formData.password) {
      setPasswordRequirements({
        length: formData.password.length >= 8,
        uppercase: /[A-Z]/.test(formData.password),
        lowercase: /[a-z]/.test(formData.password),
        number: /\d/.test(formData.password),
        special: /[!@#$%^&*(),.?":{}|<>]/.test(formData.password),
      });
    }
  }, [formData.password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    if (!Object.values(passwordRequirements).every(Boolean)) {
      setError('Password must meet all requirements');
      setLoading(false);
      return;
    }

    if (formData.companyAbbreviation.length !== 3) {
      setError('Company abbreviation must be exactly 3 letters');
      setLoading(false);
      return;
    }

    try {
      const sessionId = location.state?.session_id;
      
      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;

      // Sign in the user immediately after creation
      await login(formData.email, formData.password);

      // Now that the user is authenticated, we can write to Firestore
      const batch = writeBatch(db);

      // Create company document in Firestore
      const companyRef = doc(db, 'companies', user.uid);
      
      // Fetch subscription details
      let subscriptionDetails = null;
      if (sessionId) {
        try {
          subscriptionDetails = await getSubscriptionDetails(sessionId, user.uid);
        } catch (error) {
          console.error("Failed to fetch subscription details:", error);
          // Decide how to handle this error - you might want to continue with the setup
          // or show an error to the user
        }
      }

      batch.set(companyRef, {
        name: formData.companyName,
        abbreviation: formData.companyAbbreviation.toUpperCase(),
        size: formData.companySize,
        industry: formData.industry,
        createdAt: new Date().toISOString(),
        roles: defaultRoles,
        subscriptionDetails: subscriptionDetails
      });

      // Create user document in Firestore
      const userRef = doc(db, 'users', user.uid);
      batch.set(userRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        companyId: user.uid,
        role: 'admin',
        permissions: defaultRoles.admin.permissions,
        createdAt: new Date().toISOString(),
        hasCompletedSetup: true
      });

      // Create a default permissions document
      const permissionsRef = doc(db, 'permissions', user.uid);
      batch.set(permissionsRef, {
        companyId: user.uid,
        roles: defaultRoles
      });

      // Commit the batch
      await batch.commit();

      setSuccess('Account created successfully! Redirecting to dashboard...');
      setTimeout(() => navigate('/dashboard'), 3000);
    } catch (error) {
      console.error('Error setting up account:', error);
      setError(error.message || 'An error occurred during account setup');
      
      // Send failure notification
      try {
        const functions = getFunctions();
        const sendFailureNotification = httpsCallable(functions, 'sendFailureNotification');
        await sendFailureNotification({ error: error.message, userEmail: formData.email });
      } catch (emailError) {
        console.error('Error sending failure notification:', emailError);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Left side - Account Setup Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center px-8 lg:px-16 py-12">
        <div className="w-full max-w-md">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
              Set up your account
            </span>
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                Email address
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  readOnly
                  value={formData.email}
                  className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-2">
                  First Name
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    required
                    readOnly
                    value={formData.firstName}
                    className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-2">
                  Last Name
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    required
                    readOnly
                    value={formData.lastName}
                    className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"
                  />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                Password
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                  className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-2">
                Confirm Password
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="bg-gray-100 p-4 rounded-md">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Password Requirements:</h3>
              <ul className="space-y-1">
                <li className={`flex items-center text-sm ${passwordRequirements.length ? 'text-green-600' : 'text-gray-600'}`}>
                  {passwordRequirements.length ? <Check size={16} className="mr-2" /> : '❌'} At least 8 characters
                </li>
                <li className={`flex items-center text-sm ${passwordRequirements.uppercase ? 'text-green-600' : 'text-gray-600'}`}>
                  {passwordRequirements.uppercase ? <Check size={16} className="mr-2" /> : '❌'} At least one uppercase letter
                </li>
                <li className={`flex items-center text-sm ${passwordRequirements.lowercase ? 'text-green-600' : 'text-gray-600'}`}>
                  {passwordRequirements.lowercase ? <Check size={16} className="mr-2" /> : '❌'} At least one lowercase letter
                </li>
                <li className={`flex items-center text-sm ${passwordRequirements.number ? 'text-green-600' : 'text-gray-600'}`}>
                  {passwordRequirements.number ? <Check size={16} className="mr-2" /> : '❌'} At least one number
                </li>
                <li className={`flex items-center text-sm ${passwordRequirements.special ? 'text-green-600' : 'text-gray-600'}`}>
                  {passwordRequirements.special ? <Check size={16} className="mr-2" /> : '❌'} At least one special character 
                </li>
              </ul>
            </div>

            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-2">
                Company Name
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Briefcase className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="companyName"
                  name="companyName"
                  type="text"
                  required
                  value={formData.companyName}
                  onChange={handleChange}
                  className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="companyAbbreviation" className="block text-sm font-medium text-gray-700 mb-2">
                Company Abbreviation (3 letters)
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Briefcase className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="companyAbbreviation"
                  name="companyAbbreviation"
                  type="text"
                  required
                  minLength={3}
                  maxLength={3}
                  value={formData.companyAbbreviation}
                  onChange={handleChange}
                  className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm uppercase"
                />
              </div>
            </div>

            <div>
              <label htmlFor="companySize" className="block text-sm font-medium text-gray-700 mb-2">
                Company Size
              </label>
              <div className="relative rounded-md shadow-sm">
                <select
                  id="companySize"
                  name="companySize"
                  required
                  value={formData.companySize}
                  onChange={handleChange}
                  className="appearance-none block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Select company size</option>
                  <option value="1-10">1-10 employees</option>
                  <option value="11-50">11-50 employees</option>
                  <option value="51-200">51-200 employees</option>
                  <option value="201-500">201-500 employees</option>
                  <option value="501+">501+ employees</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <Building className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="industry" className="block text-sm font-medium text-gray-700 mb-2">
                Industry
              </label>
              <div className="relative rounded-md shadow-sm">
                <select
                  id="industry"
                  name="industry"
                  required
                  value={formData.industry}
                  onChange={handleChange}
                  className="appearance-none block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Select industry</option>
                  {industryOptions.map((industry) => (
                    <option key={industry} value={industry}>
                      {industry}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <Building className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>

            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{error}</h3>
                  </div>
                </div>
              </div>
            )}

            {success && (
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <Check className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">{success}</h3>
                  </div>
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? (
                  <Loader className="animate-spin h-5 w-5 mr-3" />
                ) : (
                  'Set up account'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Right side - Blue gradient background */}
      <div className="hidden lg:block lg:w-1/2 bg-gradient-to-r from-blue-500 to-indigo-600">
        {/* You can add a decorative element or leave it empty */}
      </div>
    </div>
  );
};

export default AccountSetupPage;