import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();

const createCheckoutSession = async (priceId) => {
  try {
    const createCheckoutSessionFunction = httpsCallable(functions, 'createCheckoutSession');
    const result = await createCheckoutSessionFunction({ priceId });
    return result.data;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    return { error: 'Failed to create checkout session. Please try again or contact support.' };
  }
};

const verifySubscription = async (sessionId) => {
  try {
    const verifySubscriptionFunction = httpsCallable(functions, 'verifySubscription');
    const result = await verifySubscriptionFunction({ sessionId });
    return result.data;
  } catch (error) {
    console.error('Error verifying subscription:', error);
    return { error: 'Failed to verify subscription. Please try again or contact support.' };
  }
};

const subscriptionCache = new Map();

const getSubscriptionDetails = async (subscriptionId, companyId) => {
  if (subscriptionCache.has(subscriptionId)) {
    console.log("Returning cached subscription details for ID:", subscriptionId);
    return subscriptionCache.get(subscriptionId);
  }

  console.log("Fetching subscription details for ID:", subscriptionId, "and company ID:", companyId);
  try {
    const functions = getFunctions();
    const getSubscriptionDetailsFunction = httpsCallable(functions, 'getSubscriptionDetails');
    const result = await getSubscriptionDetailsFunction({ subscriptionId, companyId });
    
    console.log("Raw subscription details from Stripe:", result.data);

    const subscriptionDetails = result.data;
    
    if (!subscriptionDetails || typeof subscriptionDetails !== 'object') {
      throw new Error('Invalid subscription details received');
    }

    const formattedDetails = {
      id: subscriptionDetails.id || 'Unknown',
      status: subscriptionDetails.status || 'Unknown',
      subscriptionName: subscriptionDetails.plan || 'Unknown Plan',
      stripePortalUrl: subscriptionDetails.stripePortalUrl || '',
      willCancel: subscriptionDetails.cancel_at_period_end || false,
      billingCycle: subscriptionDetails.interval || 'unknown',
      currentPeriodEnd: subscriptionDetails.currentPeriodEnd,
      activeUsers: subscriptionDetails.quantity || 0,
      maxUsers: subscriptionDetails.metadata?.maxUsers || 'Unlimited',
      totalCases: subscriptionDetails.totalCases || 0,
      productName: subscriptionDetails.productName || 'Unknown Product',
      productId: subscriptionDetails.productId || 'Unknown',
      nextBillingDate: subscriptionDetails.currentPeriodEnd 
        ? new Date(subscriptionDetails.currentPeriodEnd * 1000).toLocaleDateString()
        : 'N/A',
      amount: subscriptionDetails.amount ? (subscriptionDetails.amount / 100).toFixed(2) : '0.00',
      currency: subscriptionDetails.currency?.toUpperCase() || 'USD',
      metadata: subscriptionDetails.metadata || {},
    };

    console.log("Formatted subscription details:", formattedDetails);
    subscriptionCache.set(subscriptionId, formattedDetails);
    return formattedDetails;
  } catch (error) {
    console.error('Error getting subscription details:', error);
    return { error: `Failed to get subscription details: ${error.message}. Please try again or contact support.` };
  }
};

const updateSubscription = async (subscriptionId, newPriceId) => {
  try {
    const updateSubscriptionFunction = httpsCallable(functions, 'updateSubscription');
    const result = await updateSubscriptionFunction({ subscriptionId, newPriceId });
    return result.data;
  } catch (error) {
    console.error('Error updating subscription:', error);
    return { error: 'Failed to update subscription. Please try again or contact support.' };
  }
};

export {
  createCheckoutSession,
  verifySubscription,
  getSubscriptionDetails,
  updateSubscription
};