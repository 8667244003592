import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useAppContext } from './AppContext';
import { AppProvider } from './AppContext';
import Dashboard from './Dashboard';
import AddCasePage from './AddCasePage';
import CaseDetailPage from './CaseDetailPage';
import EmployeesPage from './EmployeesPage';
import AddEmployeePage from './AddEmployeePage';
import EmployeeDetailPage from './EmployeeDetailPage';
import ImportEmployeesPage from './ImportEmployeesPage';
import ReportsPage from './ReportsPage';
import SettingsPage from './SettingsPage'; // Add this import
import LoginForm from './LoginForm';
import UserProfile from './UserProfile';
import AuthenticatedLayout from './AuthenticatedLayout';
import SuccessPage from './SuccessPage'; // New import
import AccountSetupPage from './AccountSetupPage'; // New import
import EmployeeManagementPage from './EmployeeManagementPage';
import CaseOverviewPage from './CaseOverviewPage';
import CaseSummaryReport from './reports/CaseSummaryReport'; // Updated import
import CaseStatusReportPage from './reports/CaseStatusReportPage';
import CaseOutcomeReportPage from './reports/CaseOutcomeReportPage';
import CaseloadReportPage from './reports/CaseloadReport';
import { PermissionsProvider } from './contexts/PermissionsContext';
import { AuthProvider } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { usePermissions } from './contexts/PermissionsContext';

// Create a client
const queryClient = new QueryClient();

function App() {
  const { user } = useAuth();
  const { isInitialized } = useAppContext();

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppProvider>
          <PermissionsProvider>
            <Router>
              <AppContent />
            </Router>
          </PermissionsProvider>
        </AppProvider>
      </AuthProvider>
    </QueryClientProvider>
  </ErrorBoundary>
  );
}

function AppContent() {
  const { user, loading: authLoading } = useAuth();
  const { currentCompany, loading: appLoading, isInitialized } = useAppContext();
  const { loading: permissionsLoading } = usePermissions();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (authLoading || appLoading || !isInitialized || permissionsLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/account-setup" element={<AccountSetupPage />} />

      {/* Protected routes */}
      {user ? (
        <Route element={<AuthenticatedLayout isMobile={isMobile} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/cases" element={<CaseOverviewPage />} />
          <Route path="/cases/add" element={<AddCasePage />} />
          <Route path="/cases/:caseId" element={<CaseDetailPage />} />
          <Route path="/employees" element={<EmployeesPage />} />
          <Route path="/employees/manage" element={<EmployeeManagementPage />} />
          <Route path="/employees/:employeeId" element={<EmployeeDetailPage />} />
          <Route 
            path="/reports" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <ReportsPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/case-summary" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseSummaryReport /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/case-status" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseStatusReportPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/case-outcome" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseOutcomeReportPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/caseload" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseloadReportPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          {/* Remove the trend-reporting route */}
          <Route path="/profile" element={<UserProfile />} />
          <Route 
            path="/settings" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <SettingsPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" replace />} />
      )}
    </Routes>
  );
}

export default App;